import { AxiosResponse, CancelTokenSource } from 'axios';

import CheckPartnerIdResponse from 'api/auth/responses/CheckPartnerIdResponse';
import CreateAccountResponse from 'api/auth/responses/CreateAccountResponse';
import CreateProfileRequest from 'api/auth/responses/CreateProfileRequest';
import GettingStartedStateResponse from 'api/auth/responses/GettingStartedStateResponse';
import LoginResponse from 'api/auth/responses/LoginResponse';
import RefreshResponse from 'api/auth/responses/RefreshResponse';
import ResetPasswordResponse from 'api/auth/responses/ResetPasswordResponse';
import UserInfoResponse from 'api/auth/responses/UserInfoResponse';
import UserPermissionsResponse from 'api/auth/responses/UserPermissionsResponse';
import UserResponse from 'api/auth/responses/UserResponse';
import AccountBody from 'api/auth/types/create-account/AccountBody';
import PresignedUploadUrl, {
  RequiredHeaders,
} from 'api/auth/types/create-account/PresignedUploadUrl';
import TooltipStatus from 'api/auth/types/currency/TooltipStatus';
import ValidateCurrencyRequestBody from 'api/auth/types/currency/ValidateCurrencyRequestBody';
import ValidatedCurrency from 'api/auth/types/currency/ValidatedCurrency';
import ApiBase from 'api/common/ApiBase';
import Axios from 'api/common/Axios';
import ListResponse from 'api/common/responses/ListResponse';
import ObjectResponse from 'api/common/responses/ObjectResponse';
import SuccessResponse from 'api/common/responses/SuccessResponse';
import ApiError from 'api/common/types/ApiError';
import { AuthApiTags } from 'constants/request-tags/RequestTags';
import TooltipType from 'shared/enums/TooltipType';

class AuthApi extends ApiBase {
  async LoginAsync(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    const body = {
      email,
      password,
      rememberMe,
    };
    const loginResponse = await this.TokenAsync({
      body,
      tag: AuthApiTags.LoginAsync,
    });

    return loginResponse;
  }

  async LogoutAsync(): Promise<void> {
    const action = 'auth/logout';
    await this.PostAsync({ action, tag: AuthApiTags.LogoutAsync });
  }

  async RefreshAsync(): Promise<RefreshResponse> {
    const response = await this.RefreshTokenAsync<RefreshResponse>({
      tag: AuthApiTags.RefreshAsync,
    });
    return response;
  }

  /* account-creation */
  async VerifyNewUser(
    code: string,
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<UserResponse> {
    const action = 'user/verify';
    const body = {
      email,
      code,
    };

    const userData = this.PostAsync<UserResponse>({
      action,
      anonymous: true,
      includeAuthToken: false,
      body,
      tag: AuthApiTags.VerifyNewUser,
      cancelSource: cancelToken,
    });
    return userData;
  }

  async VerifyEmailExistence(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    const encodedEmail = encodeURIComponent(email);
    const action = `user/email-exists/${encodedEmail}`;
    const response = await this.GetAsync<{ exists: boolean }>({
      action,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.VerifyEmailExistence,
      cancelSource: cancelToken,
    });
    return response.exists;
  }

  async CheckPartnerIdExists(
    partnerId: string,
    cancelToken: CancelTokenSource
  ): Promise<CheckPartnerIdResponse> {
    const path = `account/partner-id-exists/${partnerId}`;

    const action = `${path}`;
    const partnerIdExistsResponse = this.GetAsync<CheckPartnerIdResponse>({
      action,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.CheckPartnerIdExists,
      cancelSource: cancelToken,
    });

    return partnerIdExistsResponse;
  }

  async CreateAccount(
    values: AccountBody,
    cancelToken: CancelTokenSource
  ): Promise<CreateAccountResponse> {
    const path = `account`;

    const accountResponse = this.PostAsync<CreateAccountResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.CreateAccount,
      cancelSource: cancelToken,
      body: values,
    });

    return accountResponse;
  }

  async ResendEmail(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const encodedEmail = encodeURIComponent(email);

    const path = `user/resend-verification/${encodedEmail}`;

    const resendEmailResponse = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.ResendEmail,
      cancelSource: cancelToken,
    });

    return resendEmailResponse;
  }

  async GetUserPermissions(): Promise<UserPermissionsResponse> {
    const path = `user/permissions`;

    const userPermissions = this.GetAsync<UserPermissionsResponse>({
      action: path,
      tag: AuthApiTags.GetUserPermissions,
    });

    return userPermissions;
  }

  async GetGettingStartedState(): Promise<GettingStartedStateResponse> {
    const path = `user/get-started-status`;

    const gettingStartedStateResponse =
      this.GetAsync<GettingStartedStateResponse>({
        action: path,
        tag: AuthApiTags.GetGettingStartedState,
      });

    return gettingStartedStateResponse;
  }

  async GetUserInfo(): Promise<UserInfoResponse> {
    const path = `user/info`;

    const userInfoResponse = this.GetAsync<UserInfoResponse>({
      action: path,
      tag: AuthApiTags.GetUserInfo,
    });

    return userInfoResponse;
  }

  async SendResetPasswordEmail(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<ResetPasswordResponse> {
    const encodedEmail = encodeURIComponent(email);

    const path = `user/reset-password/${encodedEmail}`;

    const resetPasswordEmailResponse = this.PostAsync<ResetPasswordResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.SendResetPasswordEmail,
      cancelSource: cancelToken,
    });

    return resetPasswordEmailResponse;
  }

  async VerifyUserForPasswordReset(
    code: string,
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = 'user/reset-password/status';

    const body = {
      email,
      code,
    };

    const verifyResetPasswordResponse = this.PostAsync<SuccessResponse>({
      action,
      anonymous: true,
      includeAuthToken: false,
      body,
      tag: AuthApiTags.VerifyUserForPasswordReset,
      cancelSource: cancelToken,
    });

    return verifyResetPasswordResponse;
  }

  async ResetPassword(
    code: string,
    email: string,
    password: string,
    cancelToken: CancelTokenSource
  ): Promise<UserResponse> {
    const action = 'user/reset-password';

    const body = {
      email,
      password,
      code,
    };

    const resetPasswordResponse = this.PostAsync<UserResponse>({
      action,
      anonymous: false,
      includeAuthToken: false,
      body,
      tag: AuthApiTags.ResetPassword,
      cancelSource: cancelToken,
    });

    return resetPasswordResponse;
  }

  async CreateProfile(
    profile: CreateProfileRequest,
    cancelToken: CancelTokenSource
  ): Promise<UserResponse> {
    const path = 'user/profile';

    const createProfileResponse = this.PostAsync<UserResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      body: profile,
      tag: AuthApiTags.CreateProfile,
      cancelSource: cancelToken,
    });

    return createProfileResponse;
  }

  async GetImageUploadUrl(
    fileName: string,
    email: string,
    code: string,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<PresignedUploadUrl>> {
    const path = `user/profile-image/upload-url`;

    const body = {
      fileName,
      email,
      code,
    };

    const uploadUrlResponse = this.PostAsync<
      ObjectResponse<PresignedUploadUrl>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      body,
      tag: AuthApiTags.GetImageUploadUrl,
      cancelSource: cancelToken,
    });

    return uploadUrlResponse;
  }

  UploadProfileImage = async (
    url: string,
    requiredHeaders: RequiredHeaders,
    file: Blob,
    cancelToken: CancelTokenSource
  ): Promise<AxiosResponse> => {
    const headers = {
      ...requiredHeaders,
      'Content-Type': 'application/octet-stream',
    };

    const uploadUrlResponse = await Axios({
      url,
      method: 'PUT',
      headers,
      data: file,
      cancelToken: cancelToken.token,
    });

    const { status, statusText } = uploadUrlResponse;
    return new Promise((resolve, reject) => {
      if (uploadUrlResponse.status === 200) {
        resolve(uploadUrlResponse);
      } else {
        const error = new ApiError(
          `Request failed with status ${status}`,
          status,
          statusText
        );
        reject(error);
      }
    });
  };

  async GetInviteStatus(
    code: string,
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const encodedEmail = encodeURIComponent(email);
    const path = `user/invite-status`;

    const queryParams = { code, email: encodedEmail };

    const inviteStatusResponse = this.GetAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.GetInviteStatus,
      cancelSource: cancelToken,
      queryParams,
    });

    return inviteStatusResponse;
  }

  async ResendProfileInvite(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const encodedEmail = encodeURIComponent(email);
    const path = `user/resend-invite/${encodedEmail}`;

    const response = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      body: {},
      tag: AuthApiTags.ResendProfileInvite,
      cancelSource: cancelToken,
    });

    return response;
  }

  async ValidateCurrency(
    body: ValidateCurrencyRequestBody
  ): Promise<ObjectResponse<ValidatedCurrency>> {
    const path = `user/validate-currency`;
    const response = this.PostAsync<ObjectResponse<ValidatedCurrency>>({
      action: path,
      body,
      tag: AuthApiTags.ValidateCurrency,
    });
    return response;
  }

  async GetTooltipStatus(
    tooltips: TooltipType[]
  ): Promise<ListResponse<TooltipStatus>> {
    const path = `user/tooltips`;
    const queryParams = { tooltip: tooltips };
    const response = this.GetAsync<ListResponse<TooltipStatus>>({
      action: path,
      tag: AuthApiTags.GetTooltipStatus,
      queryParams,
    });

    return response;
  }

  async UpdateTooltipStatus(
    tooltips: TooltipStatus[]
  ): Promise<SuccessResponse> {
    const path = `user/tooltips`;

    const body = { tooltipStatus: tooltips };
    const response = this.PostAsync<SuccessResponse>({
      action: path,
      tag: AuthApiTags.UpdateTooltipStatus,
      body,
    });

    return response;
  }
}

const AuthApiInstance = new AuthApi();

export default AuthApiInstance;
