/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  Align,
  ListOnItemsRenderedProps,
  ListOnScrollProps,
  ListProps,
  VariableSizeList as List,
} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Col, Row } from 'reactstrap';

import ProjectItem from 'api/projects/types/ProjectItem';
import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import mergeRefs from 'helpers/mergeRefs';
import PermissionUtil from 'helpers/PermissionUtil';
import ImageComponent from 'shared/components/image/ImageComponent';
import InsLink from 'shared/components/ins-link/InsLink';
import Status from 'shared/enums/Status';
import createAProject from 'shared/static/img/create-a-project.svg';
import noAssignedProject from 'shared/static/img/password_new.svg';
import createAProjectThumb from 'shared/static/img/thumbs/create-a-project_thumb.png';
import noAssignedProjectThumb from 'shared/static/img/thumbs/password_new_thumb.png';

import ProjectListItem from './project-list-item/ProjectListItem';
import styles from './projectList.module.scss';
import ProjectListHandle from './ProjectListHandle';
import ProjectListProps from './ProjectListProps';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + 56}px`,
    }}
    {...rest}
  />
));

const ProjectList: React.ForwardRefRenderFunction<
  ProjectListHandle,
  ProjectListProps
> = (props: ProjectListProps, ref) => {
  const {
    appContext,
    list,
    projectsStatus,
    projectStats,
    projectStatsStatus,
    page,
    pageCount,
    pageSize,
    scrolling,
    guideProjectId,
    showGroupsGuide,
    setShowGroupsGuide,
    loadNextPage,
  } = props;

  const infiniteLoaderRef = useRef<InfiniteLoader>();
  const listRef = useRef<List>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listOuterRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listInnerRef = useRef<any>(null);

  const [listScrollOffset, setListScrollOffset] = useState<number>(0);

  const maxHeight =
    (listInnerRef.current &&
      listInnerRef.current.style.height.replace('px', '')) ||
    900;
  const minHeight = 0;
  const pageOffset = 30;

  const [pageUp, pageDown, arrowUp, arrowDown] = [33, 34, 38, 40];

  const scrollKeys = {
    [pageUp]: Math.max(minHeight, listScrollOffset - pageOffset),
    [pageDown]: Math.min(listScrollOffset + pageOffset, maxHeight),
    [arrowUp]: Math.max(minHeight, listScrollOffset - pageOffset),
    [arrowDown]: Math.min(listScrollOffset + pageOffset, maxHeight),
  };

  /**
   * Handles key down events on the list wrapper for scrolling purposes
   *
   * @param event Key down event
   */
  const handleKeyDown = ({ keyCode }: React.KeyboardEvent): void => {
    if (scrollKeys[keyCode]) {
      setListScrollOffset(scrollKeys[keyCode]);
    }
  };

  /**
   * Handles onScroll event for list
   *
   * @param {ListOnScrollProps}
   */
  const handleListScroll = ({ scrollOffset }: ListOnScrollProps): void =>
    setListScrollOffset(scrollOffset);

  useLayoutEffect(() => {
    if (listOuterRef && listOuterRef.current) {
      listOuterRef.current.scrollTo({
        left: 0,
        top: listScrollOffset,
        behavior: 'auto',
      });
    }
  });

  useImperativeHandle(ref, () => ({
    resetloadMoreItemsCache(autoReload?: boolean): void {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(autoReload);
      }
    },
    scrollToItem(index: number, align?: Align): void {
      if (listRef.current) {
        listRef.current.scrollToItem(index, align);
      }
    },
  }));

  const canCreateProject = PermissionUtil.Can(
    appContext.permissionsData.claims,
    ResourceKeys.ProjectsCreateNewProject
  );

  // pageCount - 1 is max page number; since pages start from 0
  const hasNextPage = page < pageCount - 1;

  const loadMoreItems = (
    startIndex: number,
    stopIndex: number
  ): Promise<void> => {
    if (projectsStatus === Status.Loading) {
      return Promise.resolve();
    }
    return loadNextPage(startIndex, stopIndex);
  };

  const firstTimeLoading =
    projectsStatus === Status.Loading && list.length <= 0;

  const showEmpty = projectsStatus === Status.Success && list.length <= 0;

  const itemCount = hasNextPage ? list.length + 1 : list.length;

  const isItemLoaded = useCallback(
    (index: number): boolean => !hasNextPage || index < list.length,
    [hasNextPage, list.length]
  );

  const handleItemsRendered =
    (onItemsRenderedIL: ListProps['onItemsRendered']) =>
    (listItemProps: ListOnItemsRenderedProps): void => {
      if (onItemsRenderedIL) {
        onItemsRenderedIL(listItemProps);
      }
    };

  const renderEmptyContent = (): JSX.Element => (
    <div className={styles.empty}>
      <Row>
        <Col xs="12" className="text-center">
          <h2>
            {intl.get(
              canCreateProject
                ? 'LBL_PROJECTS_CREATE_FIRST_PROJECT_TITLE'
                : 'LBL_PROJECTS_EMPTY_VIEW_TITLE'
            )}
          </h2>
        </Col>
        <Col xs="12">
          <ImageComponent
            loading="eager"
            alt={
              canCreateProject ? 'Create your first project' : 'Nothing to see'
            }
            src={canCreateProject ? createAProject : noAssignedProject}
            thumb={
              canCreateProject ? createAProjectThumb : noAssignedProjectThumb
            }
          />
        </Col>
        <Col xs="12" className="text-center">
          <Row className="justify-content-center">
            <Col xs="auto" className="mb-2">
              {canCreateProject && (
                <InsLink
                  to={`${ModulePaths.ProjectsPath}${ModulePaths.ProjectCreatePath}`}
                  className="btn btn-secondary"
                >
                  {intl.get('BTN_PROJECTS_CREATE_FIRST_PROJECT')}
                </InsLink>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs="12">
          <Row>
            <Col md="6" className="mx-auto">
              <p className="text-center text-gray text-14-medium">
                {intl.get(
                  canCreateProject
                    ? 'LBL_PROJECTS_DEFINITION_PARA_ONE'
                    : 'LBL_PROJECTS_NOT_ASSIGNED_DEFINITION_PARA_ONE'
                )}
              </p>
              <p className="text-center text-gray text-14-medium">
                {intl.get(
                  canCreateProject
                    ? 'LBL_PROJECTS_DEFINITION_PARA_TWO'
                    : 'LBL_PROJECTS_NOT_ASSIGNED_DEFINITION_PARA_TWO'
                )}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  const renderProjectList = (projectItems: Array<ProjectItem>): JSX.Element => (
    <div className={styles.scroll}>
      <AutoSizer>
        {({ height, width }): JSX.Element => (
          <InfiniteLoader
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={infiniteLoaderRef}
            threshold={pageSize}
            minimumBatchSize={pageSize}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            itemCount={itemCount}
          >
            {({ onItemsRendered, ref: innerRef }): JSX.Element => (
              <div role="list" tabIndex={0} onKeyDown={handleKeyDown}>
                <List
                  ref={mergeRefs([innerRef, listRef])}
                  innerRef={listInnerRef}
                  outerRef={listOuterRef}
                  className="List"
                  height={height}
                  width={width}
                  itemCount={itemCount}
                  innerElementType={innerElementType}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  projectStatus={projectsStatus}
                  useIsScrolling
                  itemSize={(): number => 284}
                  itemData={{
                    list: projectItems,
                    projectStats,
                    projectStatsStatus,
                    guideProjectId,
                    showGroupsGuide,
                    isItemLoaded,
                    setShowGroupsGuide,
                    permissions: appContext.permissionsData,
                  }}
                  itemKey={(index, data): string =>
                    data?.[index]?.projectId ?? index
                  }
                  onItemsRendered={handleItemsRendered(onItemsRendered)}
                  onScroll={handleListScroll}
                >
                  {ProjectListItem}
                </List>
              </div>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
      {itemCount > 2 ? <span className={styles.track} /> : ''}
    </div>
  );

  return (
    <BlockUi tag="div" blocking={firstTimeLoading || scrolling}>
      {showEmpty ? renderEmptyContent() : renderProjectList(list)}
    </BlockUi>
  );
};

export default forwardRef(ProjectList);
