import { Component, createRef } from 'react';

import axios from 'axios';
import * as download from 'downloadjs';
import { Location } from 'history';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import queryString from 'query-string';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import ApiError from 'api/common/types/ApiError';
import ProjectsApiInstance from 'api/projects/ProjectsApi';
import SettingsApiInstance from 'api/settings/SettingsApi';
import UpdateUsersByBulkRequest from 'api/settings/types/users/UpdateUsersByBulkRequest';
import ErrorCodes from 'constants/ErrorCodes';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import Tables from 'constants/Tables';
import { formatDate } from 'helpers/DateFormat';
import { findErrorCode } from 'helpers/ErrorFormat';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import PermissionUtil from 'helpers/PermissionUtil';
import setPageTitle from 'helpers/setPageTitle';
import ProjectUsersDataTable from 'modules/private/projects/components/project-users-data-table/ProjectUsersDataTable';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import { Option as OptionType } from 'shared/components/ins-form-fields/formik-select/FormikSelectOption';
import InsLink from 'shared/components/ins-link/InsLink';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import DateFormatType from 'shared/enums/DateFormatType';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';

import ProjectUsersViewModel, {
  JobRole,
  ProjectSupervisor,
} from '../projects-view/ProjectUsersViewModel';
import SupervisorsViewModel from '../projects-view/SupervisorsViewModel';
import ProjectUsersViewProps from './ProjectUsersViewProps';
import ProjectUsersViewState, {
  ProjectUsersSearchValues,
  SelectedRows,
} from './ProjectUsersViewState';
import TableConfig from './TableConfig';

class ProjectUsersView extends Component<
  ProjectUsersViewProps,
  ProjectUsersViewState
> {
  constructor(props: ProjectUsersViewProps) {
    super(props);

    const { appContext } = props;
    const { permissionsData } = appContext;
    const canAddUsers = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.ProjectsItemAddUsers
    );
    const canEditUsers = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.ProjectsUsersEdit
    );
    const canDownloadCSV = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.ExportCSV
    );
    const canDownloadPDF = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.ExportPDF
    );

    this.saveButtonRef = createRef();
    this.okayButtonRef = createRef();

    this.state = {
      data: [],
      editModeEnabled: false,
      exportDisabled: false,
      canAddUsers,
      canEditUsers,
      canDownloadCSV,
      canDownloadPDF,
      error: null,
      status: Status.Idle,
      projectData: {
        code: '',
        name: '',
        status: Status.Idle,
      },
      pageData: {
        pageCount: 1,
        pagination: { total: 0, page: 1 },
        selectedRows: {},
      },
      edit: {
        updatedData: {},
        showRemoveUsersModal: false,
        editStatus: Status.Idle,
        jobRoles: [],
        jobRolesStatus: Status.Idle,
        createJobRoleStatus: Status.Idle,
        createJobRoleError: null,
        lastCreatedJobRoleId: undefined,
        lastJobRoleEditedRowId: undefined,
        supervisors: [],
        supervisorsFiltered: [],
        supervisorsStatus: Status.Idle,
      },
    };
  }

  componentDidMount(): void {
    const { match, location, appContext } = this.props;
    appContext.hideErrorToast();
    setPageTitle(intl.get('LBL_PROJECT_USERS_TITLE'));

    const { page, size, sortBy } = this.getTablePreferencesFromSearch(location);

    const projectId = match?.params.projectId ?? '';
    this.loadSelectedProjectData(projectId);
    this.loadProjectUsersListData(projectId, { size, page, sortBy });
    this.fetchSupervisors();
    this.fetchJobRoles();
  }

  componentDidUpdate(
    prevProps: ProjectUsersViewProps,
    prevState: ProjectUsersViewState
  ): void {
    const { match, location } = this.props;
    const { editModeEnabled } = this.state;

    const projectId = match?.params.projectId ?? '';

    if (location.search !== prevProps.location.search) {
      const prevSearch = this.getTablePreferencesFromSearch(prevProps.location);
      const currentSearch = this.getTablePreferencesFromSearch(location);
      const { page, size, sortBy } = currentSearch;
      if (
        prevSearch.size !== size ||
        prevSearch.page !== page ||
        !isEqual(prevSearch.sortBy, sortBy)
      ) {
        this.loadProjectUsersListData(projectId, { page, size, sortBy });
        this.fetchSupervisors();
        this.fetchJobRoles();
      }
    }

    if (prevState.editModeEnabled !== editModeEnabled) {
      const currentSearch = this.getTablePreferencesFromSearch(location);
      const { page, size, sortBy } = currentSearch;
      if (editModeEnabled) {
        this.fetchSupervisors();
        this.fetchJobRoles();
      } else {
        this.loadProjectUsersListData(projectId, { page, size, sortBy });
        this.setEditStateAttribute({
          updatedData: {},
          editStatus: Status.Idle,
          lastCreatedJobRoleId: undefined,
          lastJobRoleEditedRowId: undefined,
        });
      }
    }
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  saveButtonRef: React.RefObject<HTMLButtonElement>;

  okayButtonRef: React.RefObject<HTMLButtonElement>;

  /**
   * Set/Update component state in the root slice
   *
   * @param updateState Updated state as an object
   */
  setStateAttribute = (updateState: Partial<ProjectUsersViewState>): void =>
    this.setState((state) => ({
      ...state,
      ...updateState,
    }));

  /**
   * Set/Update component state in the projectData slice
   *
   * @param updateState Updated state as an object
   */
  setProjectDataStateAttribute = (
    updateState: Partial<ProjectUsersViewState['projectData']>
  ): void =>
    this.setState((state) => ({
      ...state,
      projectData: { ...state.projectData, ...updateState },
    }));

  /**
   * Set/Update component state in the pageData slice
   *
   * @param updateState Updated state as an object
   */
  setPageDataStateAttribute = (
    updateState: Partial<ProjectUsersViewState['pageData']>
  ): void =>
    this.setState((state) => ({
      ...state,
      pageData: { ...state.pageData, ...updateState },
    }));

  /**
   * Set/Update component state in the edit slice
   *
   * @param updateState Updated state as an object
   * @param callback An optional callback to fire after the updated state is set
   */
  setEditStateAttribute = (
    updateState: Partial<ProjectUsersViewState['edit']>,
    callback?
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        edit: { ...state.edit, ...updateState },
      }),
      callback
    );

  /**
   * Extract and return table preferences from location.search strinG
   *
   * @param location History.Location from react-router props
   * @returns {ProjectUsersSearchValues} Table preferences object
   */
  getTablePreferencesFromSearch = (
    location: Location
  ): ProjectUsersSearchValues => {
    let { size, page, by, desc } = queryString.parse(location.search);

    size = size && Array.isArray(size) ? null : size;
    page = page && Array.isArray(page) ? null : page;
    by = by && Array.isArray(by) ? null : by;
    desc = desc && Array.isArray(desc) ? null : desc;

    const sizeNumber = size ? parseInt(size, 10) : -1;

    const newSize = Tables.PageSizes.includes(sizeNumber)
      ? sizeNumber
      : Tables.PageSizes[0];

    const descending = desc ? desc !== 'false' : false;

    const sortColumn =
      by && TableConfig.sortCols.has(by) ? by : TableConfig.defaultSort;

    const sortBy = [{ id: sortColumn, desc: descending }];

    const pageNumber = page ? parseInt(page, 10) : -1;

    const newPage =
      pageNumber !== undefined &&
      pageNumber !== null &&
      !Number.isNaN(pageNumber) &&
      pageNumber >= 0
        ? pageNumber
        : 0;

    return { size: newSize, sortBy, page: newPage };
  };

  /**
   * Fetch project data and set to component state
   *
   * @param projectId Project ID
   */
  loadSelectedProjectData = async (projectId: string): Promise<void> => {
    try {
      this.setProjectDataStateAttribute({ status: Status.Loading });
      const response = await ProjectsApiInstance.GetProject(
        projectId,
        this.source
      );
      if (response.item) {
        const result = response.item;
        this.setProjectDataStateAttribute({
          code: result.code,
          name: result.name,
          status: Status.Success,
        });
      }
    } catch (error) {
      this.setProjectDataStateAttribute({ status: Status.Error });
    }
  };

  /**
   * Fetch project user list data and set to component state
   *
   * @param projectId Project ID
   * @param tableQuery Table preferences { pageSize, pageIndex, sortBy}
   * @param resetPageIndex if true will reset the page index to 0
   */
  loadProjectUsersListData = async (
    projectId: string,
    tableQuery: ProjectUsersSearchValues,
    resetPageIndex?: boolean
  ): Promise<void> => {
    const { appContext } = this.props;
    const { size, page, sortBy } = tableQuery;
    const Direction = Tables.SortDirection;

    this.setStateAttribute({ status: Status.Loading });

    try {
      const sortByAttribute = sortBy[0].id;
      // prettier-ignore
      const sortDirection = sortBy[0].desc ? Direction.Descending : Direction.Ascending;
      const pageIndexAltered = resetPageIndex ? 0 : page;

      const result = await ProjectsApiInstance.GetProjectUsers(
        projectId,
        this.source,
        {
          pageSize: size,
          page: pageIndexAltered,
          sortBy: sortByAttribute,
          sort: sortDirection,
        }
      );

      const pageCount = Math.ceil(result.pagination.total / size);

      this.setStateAttribute({
        data: result.items,
        exportDisabled: isEmpty(result.items),
        status: Status.Success,
      });
      this.setPageDataStateAttribute({
        pagination: result.pagination,
        pageCount,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        this.setStateAttribute({
          data: [],
          error,
          exportDisabled: true,
          status: Status.Error,
        });
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
          );
        }
      } else {
        this.setStateAttribute({
          data: [],
          error: new Error(intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')),
          exportDisabled: true,
          status: Status.Error,
        });
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
        );
      }
      this.setPageDataStateAttribute({
        pagination: { total: 0, page: 1 },
        pageCount: 0,
      });
    }
  };

  /**
   * Fetch supervisors for search query
   *
   * @param search Search string query
   */
  fetchSupervisors = async (search?: string): Promise<void> => {
    this.setEditStateAttribute({ supervisorsStatus: Status.Loading });
    const noneOption = new SupervisorsViewModel();
    let supervisors: OptionType[] = [
      { label: noneOption.name, value: noneOption.id },
    ];

    try {
      const orgUsersResponse =
        await SettingsApiInstance.LookupOrganizationUsers(this.source, search);

      const supervisorsFormatted = orgUsersResponse.items.map(
        ({ userId, name }) => ({
          label: name,
          value: userId,
        })
      );

      supervisors = [...supervisors, ...supervisorsFormatted];

      if (search === undefined) {
        this.setEditStateAttribute({
          supervisors,
          supervisorsFiltered: supervisors,
          supervisorsStatus: Status.Success,
        });
      } else {
        this.setEditStateAttribute({
          supervisorsFiltered: supervisors,
          supervisorsStatus: Status.Success,
        });
      }
    } catch (error) {
      this.setEditStateAttribute({
        supervisors: [],
        supervisorsFiltered: [],
        supervisorsStatus: Status.Error,
      });
    }
  };

  /**
   * Get job roles and set to component state
   */
  fetchJobRoles = async (createdRoleId?: string): Promise<void> => {
    this.setEditStateAttribute({ jobRolesStatus: Status.Loading });
    try {
      const response = await SettingsApiInstance.GetJobRoles(this.source);
      const jobRoles = response.items.map(({ role, id }) => ({
        value: id,
        label: role,
        ...(createdRoleId && createdRoleId === id ? { focused: true } : {}),
      }));
      this.setEditStateAttribute(
        {
          jobRoles,
          jobRolesStatus: Status.Success,
          createJobRoleStatus: Status.Idle,
        },
        this.clearFocusStatus
      );
    } catch (error) {
      this.setEditStateAttribute({
        jobRoles: [],
        jobRolesStatus: Status.Error,
        createJobRoleStatus: Status.Idle,
      });
    }
  };

  /**
   * Create a job role and update status on component state
   *
   * @param jobRole Job Role name
   */
  createJobRole = async (rowIndex: string, jobRole: string): Promise<void> => {
    const { appContext } = this.props;
    if (jobRole && jobRole.trim().length > 0) {
      this.setEditStateAttribute({
        createJobRoleStatus: Status.Loading,
      });
      try {
        const response = await SettingsApiInstance.CreateJobRole(
          jobRole,
          this.source
        );
        if (response.item) {
          const result = response.item;
          this.setEditStateAttribute({
            createJobRoleStatus: Status.Success,
            lastCreatedJobRoleId: result.id,
            // lastJobRoleEditedRowId: rowIndex,
          });

          sendEventGA(
            CategoryKeysGA.ProjectsUsersList,
            ActionKeysGA.CreateJobRole
          );
          this.fetchJobRoles(result.id);
        } else {
          throw new Error();
        }
      } catch (error) {
        const newState: Partial<ProjectUsersViewState['edit']> = {
          createJobRoleStatus: Status.Error,
          lastCreatedJobRoleId: undefined,
        };
        if (error instanceof ApiError) {
          if (error.status === HTTP_STATUS.BAD_REQUEST) {
            const duplicate = findErrorCode(error, ErrorCodes.JobRoleExists);

            if (duplicate) {
              newState.createJobRoleError = intl.get(
                'ERR_INVITE_USERS_JOB_ROLE_ALREADY_EXISTS'
              );
            }
          } else if (error.status !== HTTP_STATUS.FORBIDDEN) {
            appContext.setErrorToastText(
              intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
            );
          }
        } else {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
          );
        }
        this.setEditStateAttribute(newState);
      }
    }
  };

  /**
   * clears the job role error status and error
   */
  clearJobRoleError = (): void => {
    this.setEditStateAttribute({
      createJobRoleError: null,
      createJobRoleStatus: Status.Idle,
    });
  };

  /**
   * Formats job roles response and sets to component state
   */
  clearFocusStatus = (): void => {
    this.setEditStateAttribute({ jobRolesStatus: Status.Loading });
    const { jobRoles } = this.state.edit;
    const newJobRoles = jobRoles.map(({ value, label }) => ({ value, label }));
    this.setEditStateAttribute({
      jobRoles: newJobRoles,
      jobRolesStatus: Status.Success,
    });
  };

  /**
   * Push sortBy update to url; additionally sets page to 0
   *
   * @param sortBy sortBy object of shape { id: string, desc: boolean }
   */
  updateSortBy = (sortBy: ProjectUsersSearchValues['sortBy']): void => {
    const { history, match, location } = this.props;
    const projectId = match?.params.projectId ?? '';
    const searchParsed = queryString.parse(location.search);

    const { id: by, desc } = sortBy[0];

    if (by !== searchParsed.by || desc.toString() !== searchParsed.desc) {
      // prettier-ignore
      const query = {...searchParsed, by , desc, page: 0 };
      const url = queryString.stringifyUrl({
        url: `${ModulePaths.ProjectsPath}/${projectId}${ModulePaths.ProjectUsersPath}`,
        query,
      });
      history.push(url);
    }
  };

  /**
   * Push size update to url
   *
   * @param size Current page size
   */
  updatePageSize = (size: number): void => {
    const { history, match, location } = this.props;
    const projectId = match?.params.projectId ?? '';

    const searchParsed = queryString.parse(location.search);

    if (size.toString() !== searchParsed.size) {
      const query = { ...searchParsed, size, page: 0 };
      const url = queryString.stringifyUrl({
        url: `${ModulePaths.ProjectsPath}/${projectId}${ModulePaths.ProjectUsersPath}`,
        query,
      });
      history.push(url);
    }
  };

  /**
   * Push page update to url
   *
   * @param page Current page
   */
  updatePage = (page: number): void => {
    const { history, match, location } = this.props;
    const projectId = match?.params.projectId ?? '';

    const searchParsed = queryString.parse(location.search);

    if (page.toString() !== searchParsed.page) {
      const query = { ...searchParsed, page };
      const url = queryString.stringifyUrl({
        url: `${ModulePaths.ProjectsPath}/${projectId}${ModulePaths.ProjectUsersPath}`,
        query,
      });
      history.push(url);
    }
  };

  /**
   * Sets selected rows to component state
   *
   * @param selectedRows Collection of currently selected rows
   */
  setSelectedRows = (selectedRows: SelectedRows): void => {
    this.setPageDataStateAttribute({
      selectedRows,
    });
  };

  /**
   * Toggles edit mode and sets updated data and selected rows to default state
   */
  toggleEditMode = (): void => {
    const { editModeEnabled: editMode } = this.state;
    this.setStateAttribute({
      editModeEnabled: !editMode,
    });
  };

  /**
   * Toggles the remove users modal
   */
  toggleRemoveUsersModal = (): void => {
    const { edit } = this.state;
    this.setEditStateAttribute({
      showRemoveUsersModal: !edit.showRemoveUsersModal,
    });
  };

  /**
   * Handle row click action to navigate to user profile
   */
  handleRowClick = (f): void => f;

  /**
   * Adds an updated user to component state
   *
   * @param userId ID of the updated user
   * @param columnId Name of the updated user property
   * @param value Updated property value
   */
  onUpdateUser = (
    userId: string,
    columnId: string,
    value: JobRole | ProjectSupervisor
  ): void => {
    const { data, edit } = this.state;
    const idKeys = Object.keys(edit.updatedData);
    if (idKeys.includes(userId)) {
      let updatedUser: ProjectUsersViewModel;
      if (value.id === 'NONE') {
        /* If the original supervisor selection was 'None' and 
        the user updates to another supervisor and decides to 
        revert back to 'None' (in same edit session), update 
        the supervisor property as null */
        updatedUser = {
          ...edit.updatedData[userId],
          [columnId]: null,
        };
      } else {
        updatedUser = {
          ...edit.updatedData[userId],
          [columnId]: value,
        };
      }
      const user = find(data, { userId }) ?? null;
      if (isEqual(user, updatedUser)) {
        const newKeys = idKeys.filter((key) => key !== userId);
        const newUpdatedData = pick(edit.updatedData, newKeys);
        this.setEditStateAttribute({
          updatedData: newUpdatedData,
        });
      } else {
        this.setEditStateAttribute({
          updatedData: {
            ...edit.updatedData,
            [userId]: updatedUser,
          },
        });
      }
    } else {
      const user = find(data, { userId }) ?? null;
      if (user) {
        const updatedUser: ProjectUsersViewModel = {
          ...user,
          [columnId]: value,
        };
        this.setEditStateAttribute({
          updatedData: {
            ...edit.updatedData,
            [updatedUser.userId]: updatedUser,
          },
        });
      }
    }
  };

  /**
   * Handles downloading the excel file for the project users list
   */
  onExcelDownload = async (): Promise<void> => {
    const { match, appContext } = this.props;
    const projectId = match?.params.projectId ?? '';
    const {
      projectData: { code },
    } = this.state;
    try {
      const reportTime = new Date();
      const blob = await ProjectsApiInstance.GetProjectUsersExcel(
        projectId,
        this.source
      );
      const dateTime = formatDate(reportTime, DateFormatType.ExcelFilename);
      const fileName = `DreamSaveInsight-ProjectUsers-${code}_${dateTime}.xlsx`;
      download(blob, fileName);
      sendEventGA(CategoryKeysGA.ProjectsUsersList, ActionKeysGA.DownloadExcel);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
          );
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
        );
      }
    }
  };

  /**
   * Handles removing selected users from project
   */
  onRemoveUsers = async (): Promise<void> => {
    const { pageData } = this.state;
    const { match, appContext } = this.props;
    const projectId = match?.params.projectId ?? '';
    try {
      this.setStateAttribute({ status: Status.Loading });
      const selectedUsers = Object.keys(pageData.selectedRows);
      await ProjectsApiInstance.RemoveProjectUsers(
        projectId,
        selectedUsers,
        this.source
      );

      this.setStateAttribute({
        status: Status.Success,
      });
      appContext.setSuccessToastText(
        intl.get('LBL_TOAST_PROJECT_USERS_LIST_REMOVE_SUCCESS')
      );
      this.setEditStateAttribute({
        editStatus: Status.Success,
      });

      sendEventGA(CategoryKeysGA.ProjectsUsersList, ActionKeysGA.RemoveUsers);
      this.toggleEditMode();
      this.toggleRemoveUsersModal();
    } catch (error) {
      this.setEditStateAttribute({
        editStatus: Status.Error,
      });
      if (error instanceof ApiError) {
        this.setStateAttribute({
          error,
          status: Status.Error,
        });
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
          );
        }
      } else {
        this.setStateAttribute({
          error: new Error(intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')),
          status: Status.Error,
        });
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
        );
      }
    } finally {
      if (this.okayButtonRef && this.okayButtonRef.current) {
        this.okayButtonRef.current.blur();
      }
    }
  };

  /**
   * Handles submitting updated users
   */
  onUpdateUsers = async (): Promise<void> => {
    const { edit } = this.state;
    const { appContext } = this.props;
    try {
      this.setStateAttribute({ status: Status.Loading });
      const updatedUserObjects = Object.values(edit.updatedData);
      if (updatedUserObjects.length > 0) {
        const updatedUsers: UpdateUsersByBulkRequest[] = updatedUserObjects.map(
          ({ userId, jobRole, supervisor }: ProjectUsersViewModel) => ({
            jobRoleId: jobRole?.id ?? '',
            supervisorId: supervisor?.id ?? null,
            id: userId,
          })
        );
        await SettingsApiInstance.UpdateUsersByBulk(updatedUsers, this.source);
      }
      this.setStateAttribute({
        status: Status.Success,
      });
      appContext.setSuccessToastText(
        intl.get('LBL_TOAST_PROJECT_USERS_LIST_EDIT_SUCCESS')
      );
      this.setEditStateAttribute({
        editStatus: Status.Success,
      });

      sendEventGA(CategoryKeysGA.ProjectsUsersList, ActionKeysGA.EditUsers);

      this.toggleEditMode();
    } catch (error) {
      this.setEditStateAttribute({
        editStatus: Status.Error,
      });
      if (error instanceof ApiError) {
        this.setStateAttribute({
          error,
          status: Status.Error,
        });
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
          );
        }
      } else {
        this.setStateAttribute({
          error: new Error(intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')),
          status: Status.Error,
        });
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
        );
      }
    } finally {
      if (this.saveButtonRef && this.saveButtonRef.current) {
        this.saveButtonRef.current.blur();
      }
    }
  };

  /**
   * Renders the export button
   *
   * @returns {JSX.Element} JSX snippet containing the export button component
   */
  renderExportButton = (): JSX.Element => {
    const { exportDisabled, canDownloadCSV, canDownloadPDF } = this.state;
    return (
      <UncontrolledButtonDropdown
        disabled={exportDisabled || (!canDownloadCSV && !canDownloadPDF)}
        className="insight-btn-dropdown"
      >
        <DropdownToggle
          disabled={exportDisabled || (!canDownloadCSV && !canDownloadPDF)}
          tag="button"
          className="btn header-btn"
        >
          <i className="icon-export" />
          {intl.get('LBL_EXPORT')}
        </DropdownToggle>
        <DropdownMenu>
          {canDownloadPDF && (
            <DropdownItem tag="button" className="btn" disabled>
              <i className="icon-pdf" />
              {intl.get('LBL_PDF')}
            </DropdownItem>
          )}
          {canDownloadCSV && (
            <DropdownItem
              tag="button"
              className="btn"
              onClick={this.onExcelDownload}
            >
              <i className="icon-excel" />
              {intl.get('LBL_EXCEL')}
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  /**
   * Renders the action buttons to be displayed in the default mode
   *
   * @returns {JSX.Element} JSX snippet containing the buttons component
   */
  renderDefaultActionButtons = (): JSX.Element => {
    const { canEditUsers } = this.state;
    return (
      <Col sm="auto" className="btn-col-right">
        {this.renderExportButton()}
        {canEditUsers && (
          <Button
            className="btn btn-secondary"
            type="button"
            onClick={this.toggleEditMode}
          >
            <i className="icon-plus" />
            {intl.get('BTN_PROJECT_USERS_EDIT')}
          </Button>
        )}
      </Col>
    );
  };

  /**
   * Renders the action buttons to be displayed in edit mode
   *
   * @returns {JSX.Element} JSX snippet containing the buttons component
   */
  renderEditActionButtons = (): JSX.Element => {
    const {
      canAddUsers,
      data,
      edit: { updatedData },
      pageData: { selectedRows },
    } = this.state;
    const { match } = this.props;
    const projectId = match?.params.projectId ?? '';
    return (
      <Col sm="auto" className="btn-col-right">
        {isEmpty(selectedRows) ? (
          <InsLink
            to={`${ModulePaths.ProjectsPath}/${projectId}${ModulePaths.ProjectAddUsersPath}`}
            className="btn btn-secondary"
            hide={!canAddUsers}
          >
            <i className="icon-plus" />
            {intl.get('BTN_PROJECT_USERS_ADD_A_USER')}
          </InsLink>
        ) : (
          canAddUsers && (
            <Button
              className="btn btn-secondary"
              type="button"
              onClick={this.toggleRemoveUsersModal}
            >
              {intl.get('BTN_PROJECT_USERS_REMOVE_USER')}
            </Button>
          )
        )}
        <button
          className="btn btn-warning"
          type="button"
          onClick={this.toggleEditMode}
        >
          {intl.get('BTN_PROJECT_USERS_CANCEL')}
        </button>
        <button
          ref={this.saveButtonRef}
          className="btn btn-primary"
          type="button"
          onClick={this.onUpdateUsers}
          disabled={isEmpty(data) || isEmpty(updatedData)}
        >
          {intl.get('BTN_PROJECT_USERS_SAVE')}
        </button>
      </Col>
    );
  };

  /**
   * Renders the remove users modal
   *
   * @returns {JSX.Element} JSX snippet containing the modal component
   */
  renderRemoveUsersModal = (): JSX.Element => {
    const { status, edit, pageData } = this.state;
    const selectedRows = Object.keys(pageData.selectedRows);
    return (
      <Modal
        size="lg"
        isOpen={edit.showRemoveUsersModal}
        toggle={this.toggleRemoveUsersModal}
        backdrop="static"
        centered
        keyboard={false}
        id="removeUsersTarget"
      >
        <BlockUi tag="div" blocking={status === Status.Loading}>
          <ModalHeader className="increase-font truncate text-center">
            {selectedRows.length > 1
              ? intl.get('LBL_PROJECT_USERS_REMOVE_USERS_MULTIPLE_TITLE')
              : intl.get('LBL_PROJECT_USERS_REMOVE_USERS_SINGLE_TITLE')}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="6" className="btn-col-right">
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={this.toggleRemoveUsersModal}
                >
                  {intl.get('BTN_PROJECT_USERS_CANCEL')}
                </button>
              </Col>
              <Col xs="6">
                <button
                  ref={this.okayButtonRef}
                  className="btn btn-primary"
                  type="button"
                  onClick={this.onRemoveUsers}
                >
                  {intl.get('BTN_PROJECT_USERS_OKAY')}
                </button>
              </Col>
            </Row>
          </ModalBody>
        </BlockUi>
      </Modal>
    );
  };

  render(): JSX.Element | null {
    const {
      projectData,
      data,
      canAddUsers,
      editModeEnabled,
      pageData,
      edit,
      status,
    } = this.state;
    const { location } = this.props;
    const { page, size, sortBy } = this.getTablePreferencesFromSearch(location);

    return data ? (
      <div className="content-container">
        <Row className="align-items-center">
          <Col>
            {projectData.status === Status.Loading ? (
              <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
                <Skeleton height={20} />
              </SkeletonTheme>
            ) : (
              <Breadcrumb>
                <BreadcrumbItem>
                  {intl.get('LBL_PROJECT_USERS_BREADCRUMB_PROJECTS')}
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <EllipsisTooltip
                    tag="span"
                    data-place="top"
                    data-for="insTooltip"
                    data-tip={projectData.name}
                    data-class="overflow-wrap"
                    className="truncate"
                  >
                    {projectData.name}
                  </EllipsisTooltip>
                  <span className="ml-1">{`(#${projectData.code})`}</span>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  {intl.get('LBL_PROJECT_USERS_BREADCRUMB_USERS')}
                </BreadcrumbItem>
              </Breadcrumb>
            )}
          </Col>
          {editModeEnabled
            ? this.renderEditActionButtons()
            : this.renderDefaultActionButtons()}
        </Row>
        <Row>
          <Col>
            <hr className="divider" />
          </Col>
        </Row>
        <div>
          <ScrollToTopOnMount />
          <ProjectUsersDataTable
            data={data}
            updatedData={edit.updatedData}
            editMode={editModeEnabled}
            canAddUsers={canAddUsers}
            jobRoles={edit.jobRoles}
            jobRolesStatus={edit.jobRolesStatus}
            createJobRoleStatus={edit.createJobRoleStatus}
            lastCreatedJobRoleId={edit.lastCreatedJobRoleId}
            lastJobRoleEditedRowId={edit.lastJobRoleEditedRowId}
            supervisors={edit.supervisors}
            supervisorsFiltered={edit.supervisorsFiltered}
            supervisorsStatus={edit.supervisorsStatus}
            onCreateJobRole={this.createJobRole}
            updateSortBy={this.updateSortBy}
            updatePageSize={this.updatePageSize}
            updatePage={this.updatePage}
            pagination={pageData.pagination}
            controlledPageCount={pageData.pageCount}
            initialPageSize={size}
            initialPageIndex={page}
            initialSortBy={sortBy}
            selectedRows={pageData.selectedRows}
            setSelectedRows={this.setSelectedRows}
            onRowClick={this.handleRowClick}
            onUpdateUser={this.onUpdateUser}
            editStatus={edit.editStatus}
            status={status}
            createJobRoleError={edit.createJobRoleError}
            clearJobRoleError={this.clearJobRoleError}
          />
          {this.renderRemoveUsersModal()}
        </div>
      </div>
    ) : null;
  }
}

export default ProjectUsersView;
