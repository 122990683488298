/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Scroll from 'smoothscroll-polyfill';

import ModulePaths from 'constants/ModulePaths';
import StorageKeys from 'constants/StorageKeys';
import AppContext from 'context/AppContext';
import { initGA, pageViewGA, setUserIdGA } from 'helpers/GoogleAnalyticsHelper';
import InsightsLogger from 'helpers/logging/InsightsLogger';
import enUS from 'locales/en-US/en-US';
import AccountRoutes from 'modules/private/AccountRoutes';
import PublicRoutes from 'modules/public/PublicRoutes';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import PrivateRoute from 'shared/components/hoc/private-route/PrivateRoute';
import Toast from 'shared/components/toast/Toast';
import useUrlGlobalFilters from 'shared/hooks/use-url-global-filters/useUrlGlobalFilters';
import 'react-perfect-scrollbar/dist/css/styles.css';

const App: ReactFC = () => {
  // Load the necessary languages.
  const locales = {
    'en-US': enUS,
  };

  const [initialized, setInitialized] = useState<boolean>(false);

  /**
   * Initialize Google Analytics tracker and internationalization
   */
  useEffect(() => {
    /* Since Safari does not natively support smooth scroll with 
    window.scroll, a polyfill (https://github.com/iamdustan/smoothscroll) 
    has to be used to maintain consistency with other browsers */
    Scroll.polyfill();
    InsightsLogger.initLogging();
    initGA();
    intl
      .init({
        currentLocale: process.env.REACT_APP_DEFAULT_LOCALE,
        locales,
      })
      .then(() => setInitialized(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This is only for the initial location. Other page views
   * will be tracked in router-history location listener
   */
  useEffect(() => {
    pageViewGA(window.location.pathname);
    /**
     * This is not ideal since the token will be
     * unique for every login with same credentials
     */
    setUserIdGA(AuthStorageService.GetItem<string>(StorageKeys.TokenKey));
  }, []);

  /** manage global filter state, url
   * sync logic
   */
  useUrlGlobalFilters();

  return (
    <>
      {initialized && (
        <main id="mainWrapper" className="main-wrapper">
          <AppContext.Consumer>
            {(appContext): JSX.Element => (
              <>
                <Switch>
                  <Route path="/auth">
                    {(routeProps): JSX.Element => (
                      <PublicRoutes {...routeProps} appContext={appContext} />
                    )}
                  </Route>
                  <Route
                    exact
                    path={`${ModulePaths.WellKnownChangePasswordPath}`}
                  >
                    <Redirect
                      to={{
                        pathname: `${ModulePaths.AuthPath}${ModulePaths.AuthForgotPasswordPath}`,
                        search: window.location.search,
                      }}
                    />
                  </Route>
                  <PrivateRoute path="/">
                    {(routeProps): JSX.Element => (
                      <AccountRoutes {...routeProps} appContext={appContext} />
                    )}
                  </PrivateRoute>
                </Switch>
                <Toast
                  type="success"
                  header={appContext.successToastHeader}
                  text={appContext.successToastText}
                  isOpen={!!appContext.successToastText}
                  hideToast={appContext.hideSuccessToast}
                />
                <Toast
                  type="error"
                  header={appContext.errorToastHeader}
                  text={appContext.errorToastText}
                  isOpen={!!appContext.errorToastText}
                  hideToast={appContext.hideErrorToast}
                />
              </>
            )}
          </AppContext.Consumer>
          <ReactTooltip type="light" id="insTooltip" html />
          <ReactTooltip type="light" id="insTooltipBackupStatus" html />
          <ReactTooltip type="light" id="insTooltipCurrencyToggle" html />
          <ReactTooltip type="light" id="insTooltipTargetHint" html />
          <ReactTooltip type="light" id="insTooltipCurrencyHint" html />
          <ReactTooltip
            type="light"
            id="insTooltipClickable"
            className="custom-menu-tooltip"
            effect="solid"
            globalEventOff="click"
            clickable
            html
          />
          <ReactTooltip
            className="nivo-tooltip"
            id="svgTooltipLeftNav"
            type="light"
            html
          />
        </main>
      )}
    </>
  );
};

export default App;
