/* eslint-disable react/no-unused-state */
import { Component } from 'react';

import axios from 'axios';
import * as download from 'downloadjs';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import * as intl from 'react-intl-universal';
import { arrayMove, SortEnd } from 'react-sortable-hoc';

import AuthApiInstance from 'api/auth/AuthApi';
import ApiError from 'api/common/types/ApiError';
import DashboardApiInstance from 'api/dashboard/DashboardApi';
import FacilitatorMetric from 'api/dashboard/types/facilitator/FacilitatorMetric';
import FinancialMetric from 'api/dashboard/types/financial/FinancialMetric';
import MembershipMetric from 'api/dashboard/types/membership/MembershipMetric';
import WidgetPreference from 'api/dashboard/types/widget/WidgetPreference';
import WidgetTarget from 'api/dashboard/types/widget/WidgetTarget';
import Facilitator from 'api/messaging/types/group-filters/Facilitator';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import ModulePaths from 'constants/ModulePaths';
import { formatDate } from 'helpers/DateFormat';
import {
  defaultGlobalFilters,
  getGlobalFilters,
  getGlobalFiltersQuery,
} from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import FacilitatorMetricContainer from 'modules/private/dashboard/components/facilitator-metrics/FacilitatorMetricContainer';
import FinancialMetricContainer from 'modules/private/dashboard/components/financial-metrics/financial-metric-container/FinancialMetricContainer';
import FinancialMetricsFilter from 'modules/private/dashboard/components/financial-metrics/FinancialMetricsFilter';
import GettingStartedModal from 'modules/private/dashboard/components/getting-started-modal/GettingStartedModal';
import GroupMetricsContainer from 'modules/private/dashboard/components/group-metrics/group-metrics-container/GroupMetricsContainer';
import GroupMetricsFilters from 'modules/private/dashboard/components/group-metrics/GroupMetricsFilters';
import MembershipMetricContainer from 'modules/private/dashboard/components/membership-metrics/membership-metric-container/MembershipMetricContainer';
import MembershipMetricsFilter from 'modules/private/dashboard/components/membership-metrics/MembershipMetricsFilter';
import SortableWidgetContainer from 'modules/private/dashboard/components/widget-metrics/sortable-widget-container/SortableWidgetContainer';
import WidgetConfigs from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetConfigs';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import CurrencyStorageService from 'services/storage-services/CurrencyStorageService';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import { Option } from 'shared/components/ins-form-fields/formik-select/FormikSelectOption';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import DateFormatType from 'shared/enums/DateFormatType';
import EventKey from 'shared/enums/EventKey';
import FilterType from 'shared/enums/FilterType';
import HttpStatus from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import WidgetTargetClass from 'shared/enums/WidgetTargetClass';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import FacilitatorMetricsFilters from '../../components/facilitator-metrics/FacilitatorMetricsFilter';
import DashboardViewProps, { ModalType } from './DashboardViewProps';
import DashboardViewState from './DashboardViewState';

class DashboardView extends Component<DashboardViewProps, DashboardViewState> {
  constructor(props: DashboardViewProps) {
    super(props);

    this.state = {
      filters: defaultGlobalFilters,
      widgets: {
        widgetData: [],
        widgetsLoading: true,
      },
      financialMetrics: {
        data: Array<FinancialMetric>(),
        filters: new FinancialMetricsFilter(),
        loading: false,
        error: null,
      },
      membershipMetrics: {
        data: Array<MembershipMetric>(),
        filters: new MembershipMetricsFilter(),
        loading: false,
        error: null,
      },
      facilitatorMetrics: {
        data: new Array<FacilitatorMetric>(),
        filters: new FacilitatorMetricsFilters(),
        loading: false,
        error: null,
        isFilterUpdated: false,
      },
      groupMetrics: {
        data: [],
        filters: new GroupMetricsFilters(),
        loading: false,
        error: null,
      },
      gettingStarted: {
        isOpen: false,
        userInfo: null,
        status: Status.Idle,
      },
    };
    this.facilitators = [];
    this.supervisors = [];
  }

  componentDidMount(): void {
    const {
      appContext,
      modal,
      location: { search },
    } = this.props;
    const { globalFilters, hideErrorToast } = appContext;
    hideErrorToast();
    setPageTitle(intl.get('BTN_DASHBOARD'));

    const {
      groupMetrics: { filters: groupMetricFilters },
      financialMetrics,
      membershipMetrics,
    } = this.state;

    const urlGlobalFilters = getGlobalFilters(getGlobalFiltersQuery(search));

    // Verify global filters when initial loading
    if (isEqual(defaultGlobalFilters, urlGlobalFilters)) {
      this.loadFacilitators(globalFilters, true);
    } else {
      this.isInitialPageLoadWithGlobalFilters = true;
    }

    this.loadWidgetData(globalFilters);
    this.loadFinancialData(globalFilters, financialMetrics.filters);
    this.loadSupervisors();
    this.loadMembershipData(globalFilters, membershipMetrics.filters);
    this.loadGroupMetricsData(globalFilters, groupMetricFilters);
    this.loadUserInfo();

    this.setGettingStartedData({
      isOpen: modal === ModalType.GettingStarted,
    });
  }

  componentDidUpdate(
    prevProps: DashboardViewProps,
    prevState: DashboardViewState
  ): void {
    const {
      filters,
      groupMetrics,
      financialMetrics,
      membershipMetrics,
      facilitatorMetrics,
    } = this.state;

    const { appContext, modal } = this.props;
    const {
      setDashboardSetupCallback,
      setExcelReportDownloadCallback,
      globalFilters,
    } = appContext;

    setDashboardSetupCallback(this.saveCallback.bind(this));
    setExcelReportDownloadCallback(this.excelDownloadCallback);

    if (prevProps.modal !== modal) {
      this.setGettingStartedData({
        isOpen: modal === ModalType.GettingStarted,
      });
    }

    if (!isEqual(filters, globalFilters)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filters: globalFilters });
      const { fromDate, toDate } = globalFilters;
      if ((!fromDate && !toDate) || (fromDate && toDate)) {
        this.loadWidgetData(globalFilters);
        this.loadFinancialData(globalFilters, financialMetrics.filters);
        this.loadMembershipData(globalFilters, membershipMetrics.filters);
        this.loadGroupMetricsData(globalFilters, groupMetrics.filters);

        if (this.isInitialPageLoadWithGlobalFilters) {
          this.loadFacilitators(globalFilters, true);
          this.isInitialPageLoadWithGlobalFilters = false;
        } else {
          this.loadFacilitators(globalFilters);
        }
      }
    }

    // prettier-ignore
    if (!isEqual(prevState.financialMetrics.filters, financialMetrics.filters)) {
      this.loadFinancialData(globalFilters, financialMetrics.filters);
    }

    // prettier-ignore
    if (!isEqual(prevState.membershipMetrics.filters, membershipMetrics.filters)) {
      this.loadMembershipData(globalFilters, membershipMetrics.filters);
    }

    if (!isEqual(prevState.groupMetrics.filters, groupMetrics.filters)) {
      this.loadGroupMetricsData(globalFilters, groupMetrics.filters);
    }

    if (
      !isEqual(
        prevState.facilitatorMetrics.filters.facilitators,
        facilitatorMetrics.filters.facilitators
      ) ||
      prevState.facilitatorMetrics.isFilterUpdated !==
        facilitatorMetrics.isFilterUpdated
    ) {
      if (facilitatorMetrics.filters.facilitators.length === 0) {
        this.setFacilitatorMetricsData({
          data: new Array<FacilitatorMetric>(),
          loading: false,
          error: null,
        });
      } else {
        this.loadFacilitatorMetricsData(globalFilters);
      }
    }

    if (
      !isEqual(
        prevState.facilitatorMetrics.filters.supervisors,
        facilitatorMetrics.filters.supervisors
      )
    ) {
      this.loadFacilitators(globalFilters);
    }
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  private facilitators: Facilitator[];

  private supervisors: Option[];

  private isInitialPageLoadWithGlobalFilters = false;

  /**
   * Sets updated getting started state to container state
   *
   * @param gettingStarted Updated state object
   * @param callback Callback function to be fired after updating state
   */
  setGettingStartedData = (
    gettingStarted: Partial<DashboardViewState['gettingStarted']>,
    callback?: () => void
  ): void => {
    this.setState(
      (state) => ({
        gettingStarted: { ...state.gettingStarted, ...gettingStarted },
      }),
      callback
    );
  };

  /**
   * Sets updated facilitator metrics state to container state
   *
   * @param facilitatorMetrics Updated state object
   * @param callback Callback function to be fired after updating state
   */
  setFacilitatorMetricsData = (
    facilitatorMetrics: Partial<DashboardViewState['facilitatorMetrics']>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        facilitatorMetrics: {
          ...state.facilitatorMetrics,
          ...facilitatorMetrics,
        },
      }),
      callback
    );

  /**
   * Sets updated widgets state to container state
   *
   * @param widgetState Updated state object
   */
  setWidgetsData = (
    widgetState: Partial<DashboardViewState['widgets']>
  ): void => {
    this.setState((state) => ({
      widgets: { ...state.widgets, ...widgetState },
    }));
  };

  /**
   * Fetches currently logged-in user's info
   */
  private async loadUserInfo(): Promise<void> {
    try {
      this.setGettingStartedData({ status: Status.Loading });
      const userInfo = await AuthApiInstance.GetUserInfo();
      this.setGettingStartedData({ status: Status.Success, userInfo });
    } catch (error) {
      this.setGettingStartedData({ status: Status.Error });
    }
  }

  /**
   * Fetches widget data
   *
   * @param filters Current global filter configuration
   */
  private async loadWidgetData(filters: GlobalFilters): Promise<void> {
    const { appContext } = this.props;
    try {
      this.setWidgetsData({ widgetsLoading: true });
      const result = await DashboardApiInstance.GetWidgetData(
        filters,
        this.source
      );
      this.setWidgetsData({
        widgetsLoading: false,
        widgetData: result.items,
      });
    } catch (error) {
      this.setWidgetsData({ widgetsLoading: false });
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      }
    }
  }

  /**
   * Fetches financial metrics data
   *
   * @param globalFilters Current global filter configuration
   * @param financialFilters Current financial metrics filter configuration
   */
  private async loadFinancialData(
    globalFilters: GlobalFilters,
    financialFilters: FinancialMetricsFilter
  ): Promise<void> {
    try {
      this.setState((state) => ({
        financialMetrics: { ...state.financialMetrics, loading: true },
      }));
      const result = await DashboardApiInstance.GetDashboardFinancialMetricData(
        globalFilters,
        financialFilters,
        this.source
      );
      this.setState((state) => ({
        financialMetrics: {
          ...state.financialMetrics,
          data: result.items,
          loading: false,
          error: null,
        },
      }));
    } catch (error) {
      if (error instanceof ApiError) {
        this.setState((state) => ({
          financialMetrics: {
            ...state.financialMetrics,
            data: Array<FinancialMetric>(),
            loading: false,
            error: error as ApiError,
          },
        }));
        if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        this.setState((state) => ({
          financialMetrics: {
            ...state.financialMetrics,
            data: Array<FinancialMetric>(),
            loading: false,
            error: new Error(intl.get('ERR_TOAST_GENERIC_ERROR')),
          },
        }));
      }
    }
  }

  /**
   * Fetches membership metrics data
   *
   * @param globalFilters Current global filter configuration
   * @param membershipFilters Current membership metrics filter configuration
   */
  private async loadMembershipData(
    globalFilters: GlobalFilters,
    membershipFilters: MembershipMetricsFilter
  ): Promise<void> {
    try {
      this.setState((state) => ({
        membershipMetrics: { ...state.membershipMetrics, loading: true },
      }));
      const result = await DashboardApiInstance.GetMembershipMetricData(
        globalFilters,
        membershipFilters,
        this.source
      );
      this.setState((state) => ({
        membershipMetrics: {
          ...state.membershipMetrics,
          data: result.items,
          loading: false,
          error: null,
        },
      }));
    } catch (error) {
      if (error instanceof ApiError) {
        this.setState((state) => ({
          membershipMetrics: {
            ...state.membershipMetrics,
            data: Array<MembershipMetric>(),
            loading: false,
            error: error as ApiError,
          },
        }));
        if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        this.setState((state) => ({
          membershipMetrics: {
            ...state.membershipMetrics,
            data: Array<MembershipMetric>(),
            loading: false,
            error: new Error(intl.get('ERR_TOAST_GENERIC_ERROR')),
          },
        }));
      }
    }
  }

  /**
   * Fetches group metrics data
   *
   * @param globalFilters Current global filter configuration
   * @param groupMetricFilters Current group metrics filter configuration
   */
  private async loadGroupMetricsData(
    globalFilters: GlobalFilters,
    groupMetricFilters: GroupMetricsFilters
  ): Promise<void> {
    const { appContext } = this.props;
    try {
      this.setState((state) => ({
        groupMetrics: { ...state.groupMetrics, loading: true },
      }));
      const result = await DashboardApiInstance.GetGroupMetricsData(
        globalFilters,
        groupMetricFilters.cyclePhase,
        this.source
      );

      this.setState((state) => ({
        groupMetrics: {
          ...state.groupMetrics,
          data: result.items,
          loading: false,
          error: null,
        },
      }));
    } catch (error) {
      if (error instanceof ApiError) {
        this.setState((state) => ({
          groupMetrics: {
            ...state.groupMetrics,
            data: [],
            loading: false,
            error: error as ApiError,
          },
        }));
        if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        this.setState((state) => ({
          groupMetrics: {
            ...state.groupMetrics,
            data: [],
            loading: false,
            error: new Error(intl.get('ERR_TOAST_GENERIC_ERROR')),
          },
        }));
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  }

  /**
   * Fetches facilitator metrics data
   *
   * @param globalFilters Current global filter configuration
   */
  private async loadFacilitatorMetricsData(
    globalFilters: GlobalFilters
  ): Promise<void> {
    const { facilitatorMetrics } = this.state;

    try {
      this.setFacilitatorMetricsData({
        loading: true,
      });

      const result = await DashboardApiInstance.GetFacilitatorMetricData(
        globalFilters,
        facilitatorMetrics.filters,
        this.source
      );

      this.setFacilitatorMetricsData({
        data: result.items,
        loading: false,
        error: null,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        this.setFacilitatorMetricsData({
          data: new Array<FacilitatorMetric>(),
          loading: false,
          error,
        });
        if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        this.setFacilitatorMetricsData({
          data: new Array<FacilitatorMetric>(),
          loading: false,
          error: new Error(intl.get('ERR_TOAST_GENERIC_ERROR')),
        });
      }
    }
  }

  /**
   * Fetches facilitator data
   *
   * @param globalFilters Current global filter configuration
   * @param isInitialLoad Initial loading
   */
  private async loadFacilitators(
    globalFilters: GlobalFilters,
    isInitialLoad?: boolean
  ): Promise<void> {
    const {
      facilitatorMetrics: { filters, isFilterUpdated },
    } = this.state;
    try {
      this.setFacilitatorMetricsData({
        loading: true,
      });

      const facilitatorItems = await DashboardApiInstance.GetFacilitators(
        globalFilters,
        filters.supervisors,
        this.source
      );

      this.facilitators = facilitatorItems.items;

      const existingFacilitators = facilitatorItems.items
        .filter((facilitator) => filters.facilitators.includes(facilitator.id))
        .map((facilitator) => facilitator.id);

      const initialSelectedFacilitators =
        facilitatorItems.items.length >= 1
          ? [facilitatorItems.items[0].id]
          : [];

      const filteredFacilitators = isInitialLoad
        ? initialSelectedFacilitators
        : existingFacilitators;

      this.setFacilitatorMetricsData({
        loading: false,
        error: null,
        filters: {
          ...filters,
          facilitators: filteredFacilitators,
        },
        isFilterUpdated: !isFilterUpdated,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        this.setState((state) => ({
          facilitatorMetrics: {
            ...state.facilitatorMetrics,
            data: new Array<FacilitatorMetric>(),
            loading: false,
            error: error as ApiError,
          },
        }));
        if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        this.setState((state) => ({
          facilitatorMetrics: {
            ...state.facilitatorMetrics,
            data: new Array<FacilitatorMetric>(),
            loading: false,
            error: new Error(intl.get('ERR_TOAST_GENERIC_ERROR')),
          },
        }));
      }
    }
  }

  /**
   * Fetch supervisors list
   *
   */
  loadSupervisors = async (): Promise<void> => {
    try {
      this.setFacilitatorMetricsData({
        loading: true,
      });
      const orgUsersResponse =
        await SettingsApiInstance.LookupOrganizationUsers(this.source);

      const supervisorsFormatted = orgUsersResponse.items.map(
        ({ userId, name }) => ({
          label: name,
          value: userId,
        })
      );

      this.supervisors = supervisorsFormatted;
    } catch (error) {
      if (error instanceof ApiError) {
        this.setState((state) => ({
          facilitatorMetrics: {
            ...state.facilitatorMetrics,
            error: error as ApiError,
          },
        }));
      }
    }
  };

  /**
   * Updates widget data
   *
   * @param data Updated widget data
   */
  updateWidgetData = (data: WidgetViewModel): void => {
    const { widgets } = this.state;

    const clonedData = clone(widgets.widgetData);
    const oldWidget = clonedData.find((x) => x.position === data.position);

    if (oldWidget) {
      clonedData[clonedData.indexOf(oldWidget)] = data;

      this.setWidgetsData({ widgetData: clonedData });
    }
  };

  /**
   * Reorders widgets
   */
  reorderWidgets = ({ oldIndex, newIndex }: SortEnd): void => {
    const { widgets } = this.state;

    const orderedWidgetData = arrayMove(widgets.widgetData, oldIndex, newIndex);

    this.setWidgetsData({ widgetData: orderedWidgetData });
  };

  /**
   * Get widget preferences
   *
   * @returns {Array<WidgetPreference>} List of widget preferences
   */
  private preparePreferences(): Array<WidgetPreference> {
    const { widgets } = this.state;
    const selectedCurrency = CurrencyStorageService.GetSelectedCurrency();
    const preferences = widgets.widgetData.map(
      (m: WidgetViewModel, i: number) => {
        const preference = new WidgetPreference();
        const widgetTargetClass = WidgetConfigs.GetWidgetConfig(
          m.type
        ).targetClass;
        const targetData: WidgetTarget | null =
          m.targetData === null
            ? null
            : {
                ...m.targetData,
                currencyCode:
                  widgetTargetClass === WidgetTargetClass.Currency
                    ? selectedCurrency.currencyCode
                    : null,
              };
        preference.position = i + 1;
        preference.type = m.type;
        preference.targetData = targetData;
        preference.hasChanges = m.pendingChanges ?? false;

        return preference;
      }
    );

    return preferences;
  }

  /**
   * Tracks widget preference change events for analytics
   *
   * @param preferences List of widget preferences
   */
  widgetPreferenceChangeEventGA = (preferences: WidgetPreference[]): void => {
    const types: string[] = [];
    preferences.forEach((preference): void => {
      types.push(LabelKeysGA[`WIDGET_${preference.type}`]);
      sendEventGA(
        `${CategoryKeysGA.DashboardWidgets}/${String(
          LabelKeysGA[`WIDGET_${preference.type}`]
        )}`,
        ActionKeysGA.ReorderWidget,
        `Position: ${preference.position}`
      );
    });
    sendEventGA(
      CategoryKeysGA.DashboardWidgets,
      ActionKeysGA.SaveWidgetSetup,
      JSON.stringify(types)
    );
  };

  /**
   * Callback for saving dashboard setup preferences
   */
  private async saveCallback(): Promise<void> {
    const { appContext } = this.props;
    const { filters } = this.state;
    try {
      this.setWidgetsData({ widgetsLoading: true });
      const preferences = this.preparePreferences();
      if (preferences.length < 8) {
        throw new Error('minimum widget count unmet');
      }
      await DashboardApiInstance.SetWidgetPreferences(preferences, this.source);

      this.widgetPreferenceChangeEventGA(preferences);

      const result = await DashboardApiInstance.GetWidgetData(
        filters,
        this.source
      );
      this.setWidgetsData({
        widgetData: result.items,
        widgetsLoading: false,
      });
    } catch (error) {
      this.setWidgetsData({ widgetsLoading: false });
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  }

  /**
   * Callback for downloading the excel report
   */
  excelDownloadCallback = async (): Promise<void> => {
    const { appContext } = this.props;
    const { globalFilters } = appContext;

    try {
      const reportTime = new Date();
      const formattedDate = formatDate(reportTime, DateFormatType.BlobRequest);

      const blob = await DashboardApiInstance.GetDashboardExcel(
        globalFilters,
        formattedDate,
        this.source
      );
      const dateTime = formatDate(reportTime, DateFormatType.ExcelFilename);
      const fileName = `DreamSaveInsight-Dashboard-${dateTime}.xlsx`;
      download(blob, fileName);
      // prettier-ignore
      sendEventGA(CategoryKeysGA.DashboardReports, ActionKeysGA.DownloadExcel, fileName);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      }
    }
  };

  /**
   * Handles change event for group metrics filters
   *
   * @param filters Updated group metrics filters
   */
  private handleGroupMetricFiltersChange = (
    filters: GroupMetricsFilters
  ): void => {
    this.setState((state) => ({
      groupMetrics: { ...state.groupMetrics, filters },
    }));
  };

  /**
   * Updating the state when a filter is changed. The `type` param
   * provide the name of the update filter and the `filter` param
   * provides the selected value in the Select Controller.
   *
   * @param filter Selected filter option
   * @param type Type of filter
   */
  private handleFinancialMetricFilterChange = (
    filter: SelectFieldOption,
    type: FilterType
  ): void => {
    this.setState((state) => ({
      financialMetrics: {
        ...state.financialMetrics,
        filters: { ...state.financialMetrics.filters, [type]: { ...filter } },
      },
    }));
  };

  /**
   * Updating the state when a filter is changed. The `type` param
   * provide the name of the update filter and the `filter` param
   * provides the selected value in the Select Controller.
   *
   * @param filter Selected filter option
   * @param type Type of filter
   */
  private handleMembershipMetricFilterChange = (
    filter: SelectFieldOption,
    type: FilterType
  ): void => {
    this.setState((state) => ({
      membershipMetrics: {
        ...state.membershipMetrics,
        filters: { ...state.membershipMetrics.filters, [type]: { ...filter } },
      },
    }));
  };

  /**
   * Handles toggling the getting started modal
   */
  private handleToggleGettingStarted = (): void => {
    const { gettingStarted } = this.state;
    const { history, location } = this.props;
    const isOpen = !gettingStarted.isOpen;
    if (isOpen) {
      this.setGettingStartedData({ isOpen });
    } else {
      history.replace({
        pathname: ModulePaths.DashboardPath,
        search: getGlobalFiltersQuery(location.search),
      });
    }
  };

  /**
   * Handles facilitator selection change
   *
   * @param facilitatorId Id of the selected or unselected facilitator
   * @param isChecked Selected or unselected
   */
  private handleFacilitatorSelectionChange = (
    facilitatorId: string,
    isChecked: boolean
  ): void => {
    const {
      facilitatorMetrics: { filters },
    } = this.state;

    this.setFacilitatorMetricsData({
      filters: {
        ...filters,
        facilitators: isChecked
          ? [...filters.facilitators, facilitatorId]
          : filters.facilitators.filter(
              (selectedFacilitator) => selectedFacilitator !== facilitatorId
            ),
      },
    });
  };

  /**
   * Handles supervisor select close and update the facilitators list
   *
   * @param selectedSupervisors Selected supervisors
   */
  handleSupervisorSelectClose = (selectedSupervisors: string[]): void => {
    const {
      facilitatorMetrics: { filters },
    } = this.state;

    if (!isEqual(selectedSupervisors.sort(), filters.supervisors.sort())) {
      this.setFacilitatorMetricsData({
        filters: {
          ...filters,
          supervisors: selectedSupervisors,
        },
      });
    }
  };

  render(): JSX.Element {
    const { reorderWidgets, updateWidgetData } = this;

    const { appContext } = this.props;
    const {
      dashboardSetupInProgress,
      getGettingStartedState,
      gettingStartedStates,
      setGuideOpen,
    } = appContext;
    const {
      groupMetrics,
      widgets,
      financialMetrics,
      membershipMetrics,
      gettingStarted,
      facilitatorMetrics,
    } = this.state;

    const { filters, data: FacilitatorsData, loading } = facilitatorMetrics;

    return (
      <div className="content-container">
        <ScrollToTopOnMount />
        <SortableWidgetContainer
          widgetData={widgets.widgetData}
          widgetsLoading={widgets.widgetsLoading}
          axis="xy"
          onSortEnd={reorderWidgets}
          onUpdateWidgetData={updateWidgetData}
          dashboardSetupInProgress={dashboardSetupInProgress}
        />
        <FinancialMetricContainer
          data={financialMetrics.data}
          filters={financialMetrics.filters}
          loading={financialMetrics.loading}
          onFilterChange={this.handleFinancialMetricFilterChange}
        />
        <MembershipMetricContainer
          data={membershipMetrics.data}
          filters={membershipMetrics.filters}
          loading={membershipMetrics.loading}
          onFilterChange={this.handleMembershipMetricFilterChange}
        />
        <GroupMetricsContainer
          localFilters={groupMetrics.filters}
          data={groupMetrics.data}
          loading={groupMetrics.loading}
          error={groupMetrics.error}
          onFilterChange={this.handleGroupMetricFiltersChange}
        />
        <FacilitatorMetricContainer
          data={FacilitatorsData}
          filters={filters}
          loading={loading}
          supervisors={this.supervisors}
          facilitators={this.facilitators}
          onFacilitatorSelectionChange={this.handleFacilitatorSelectionChange}
          onCloseSupervisorSelect={this.handleSupervisorSelectClose}
        />
        <GettingStartedModal
          isOpen={gettingStarted.isOpen}
          userInfoStatus={gettingStarted.status}
          userInfo={gettingStarted.userInfo}
          onToggle={this.handleToggleGettingStarted}
          gettingStartedSteps={gettingStartedStates}
          setGuideOpen={setGuideOpen}
          fetchGettingStartedSteps={getGettingStartedState}
        />
      </div>
    );
  }
}

export default DashboardView;
