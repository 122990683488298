/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import { CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import ListResponse from 'api/common/responses/ListResponse';
import ObjectResponse from 'api/common/responses/ObjectResponse';
import SuccessResponse from 'api/common/responses/SuccessResponse';
import ListFilters from 'api/common/types/ListFilters';
import PaginatedUserListResponse from 'api/settings/responses/PaginatedUserListResponse';
import Currency from 'api/settings/types/currency/Currency';
import CurrencySettings from 'api/settings/types/currency/CurrencySettings';
import CurrencySettingsRequestBody from 'api/settings/types/currency/CurrencySettingsRequestBody';
import CreateFacilitatorByBulk from 'api/settings/types/facilitators/CreateFacilitatorByBulk';
import OrganizationFacilitator from 'api/settings/types/facilitators/OrganizationFacilitator';
import UpdateFacilitatorsByBulk from 'api/settings/types/facilitators/UpdateFacilitatorsByBulk';
import OrganizationGroupStatus from 'api/settings/types/group-status/OrganizationGroupStatus';
import JobRole from 'api/settings/types/job-roles/JobRole';
import JobRoleCreated from 'api/settings/types/job-roles/JobRoleCreated';
import OrganizationInfo from 'api/settings/types/OrganizationInfo';
import CreatePermissionRequest from 'api/settings/types/permissions/CreatePermissionRequest';
import Feature from 'api/settings/types/permissions/Feature';
import OrganizationCountry from 'api/settings/types/permissions/OrganizationCountry';
import PermissionItem from 'api/settings/types/permissions/PermissionItem';
import CreateUsersByBulkRequest from 'api/settings/types/users/CreateUsersByBulkRequest';
import OrganizationUser from 'api/settings/types/users/OrganizationUser';
import UpdateUsersByBulkRequest from 'api/settings/types/users/UpdateUsersByBulkRequest';
import User from 'api/settings/types/users/User';
import { SettingsApiTags } from 'constants/request-tags/RequestTags';
import { UserTabFilters } from 'modules/private/settings/containers/users-view/UsersViewState';
import PermissionsViewMode from 'shared/enums/PermissionsViewMode';

class SettingsApi extends ApiBase {
  /**
   * get job roles list
   */
  async GetJobRoles(
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<JobRole>> {
    const path = 'organizations/job-roles';
    const jobRolesResponse = this.GetAsync<ListResponse<JobRole>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SettingsApiTags.GetJobRoles,
      cancelSource: cancelToken,
    });
    return jobRolesResponse;
  }

  /**
   * create a job role
   * @param jobRole job role name
   */
  async CreateJobRole(
    jobRole: string,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<JobRoleCreated>> {
    const path = 'organizations/job-roles';
    const body = { jobRole };
    const createJobRoleResponse = this.PostAsync<
      ObjectResponse<JobRoleCreated>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.CreateJobRole,
      cancelSource: cancelToken,
    });
    return createJobRoleResponse;
  }

  /**
   * create a permission level
   * @param permission permission object
   */
  async CreatePermissionLevel(
    orgId: string,
    permission: CreatePermissionRequest,
    cancelToken?: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `organizations/${orgId}/permissions`;
    const body = permission;
    const createPermissionResponse = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.CreatePermissionLevel,
      cancelSource: cancelToken,
    });
    return createPermissionResponse;
  }

  /**
   * create a permission level
   * @param permission permission object
   */
  async UpdatePermissionLevel(
    orgId: string,
    permission: CreatePermissionRequest,
    cancelToken?: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `organizations/${orgId}/permissions`;
    const body = permission;
    const updatePermissionResponse = this.PutAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.UpdatePermissionLevel,
      cancelSource: cancelToken,
    });
    return updatePermissionResponse;
  }

  /**
   * Get permission levels for a given organization
   * @param orgId organization id
   */
  async GetPermissionLevels(
    orgId: string,
    viewMode: PermissionsViewMode,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<PermissionItem>> {
    const queryParams = { viewMode };

    const action = `organizations/${orgId}/permissions`;

    const permissionLevels = this.GetAsync<ListResponse<PermissionItem>>({
      action,
      tag: SettingsApiTags.GetPermissionLevels,
      cancelSource: cancelToken,
      queryParams,
    });
    return permissionLevels;
  }

  /**
   * Get default claims for a given organization
   * @param orgId organization id
   */
  async GetDefaultClaims(
    orgId: string,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Feature>> {
    const path = `organizations/${orgId}/default-claims`;
    const defaultClaims = this.GetAsync<ListResponse<Feature>>({
      action: path,
      tag: SettingsApiTags.GetDefaultClaims,
      cancelSource: cancelToken,
    });
    return defaultClaims;
  }

  async GetOrganizationCountries(
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<OrganizationCountry>> {
    const path = `organizations/countries`;
    const organizationCountries = this.GetAsync<
      ListResponse<OrganizationCountry>
    >({
      action: path,
      tag: SettingsApiTags.GetOrganizationCountries,
      cancelSource: cancelToken,
    });
    return organizationCountries;
  }

  async CreateUsersByBulk(
    body: CreateUsersByBulkRequest[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `user/bulk`;
    const createUsersByBulkResponse = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.CreateUsersByBulk,
      cancelSource: cancelToken,
    });
    return createUsersByBulkResponse;
  }

  async UpdateUsersByBulk(
    values: UpdateUsersByBulkRequest[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `user/bulk`;
    const body = {
      users: values,
    };
    const updateUsersByBulkResponse = this.PutAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.UpdateUsersByBulk,
      cancelSource: cancelToken,
    });
    return updateUsersByBulkResponse;
  }

  async UpdateFacilitatorsByBulk(
    values: UpdateFacilitatorsByBulk[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `organizations/facilitators/bulk`;
    const body = {
      facilitators: values,
    };
    const updateUsersByBulkResponse = this.PutAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.UpdateFacilitatorsByBulk,
      cancelSource: cancelToken,
    });
    return updateUsersByBulkResponse;
  }

  async CreateFacilitatorsByBulk(
    body: CreateFacilitatorByBulk[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `organizations/facilitators/bulk`;
    const createFacilitatorsByBulkResponse = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.CreateFacilitatorsByBulk,
      cancelSource: cancelToken,
    });
    return createFacilitatorsByBulkResponse;
  }

  /**
   * get users for a given organization
   * @param listFilters filters applied for list { page, pageSize, sortBy, sort }
   * @param orgId organization id
   */
  async GetOrganizationUsers(
    orgId: string,
    filter: string,
    showDeactivated: boolean,
    listFilters: Omit<ListFilters, 'search'>,
    cancelToken: CancelTokenSource
  ): Promise<PaginatedUserListResponse<User>> {
    const path = `organizations/${orgId}/users`;

    const { page, pageSize, sortBy, sort } = listFilters;

    let queryParams: Record<string, any> = {
      page,
      pageSize,
      sort,
      sortBy,
      userStatus:
        filter === UserTabFilters.INVITED ? 'VERIFICATION_PENDING' : 'ACTIVE',
    };

    if (filter === UserTabFilters.USERS && showDeactivated) {
      queryParams = {
        ...queryParams,
        userStatus: 'DEACTIVATED',
        prioritizeDeactivated: true,
      };
    }

    const action = `${path}`;

    const usersResponse = this.GetAsync<PaginatedUserListResponse<User>>({
      action,
      tag: SettingsApiTags.GetOrganizationUsers,
      cancelSource: cancelToken,
      queryParams,
    });
    return usersResponse;
  }

  /**
   * get organization users for lookup
   * @param query query to look up
   */
  async LookupOrganizationUsers(
    cancelToken: CancelTokenSource,
    query?: string
  ): Promise<ListResponse<OrganizationUser>> {
    const path = 'user/lookup';

    let queryParams: Record<string, any> = {};

    if (query) {
      const encodedQuery = encodeURIComponent(query);
      queryParams = { search: encodedQuery };
    }
    const data = this.GetAsync<ListResponse<OrganizationUser>>({
      action: path,
      tag: SettingsApiTags.LookupOrganizationUsers,
      cancelSource: cancelToken,
      queryParams,
    });
    return data;
  }

  /**
   * get organization users for lookup
   * @param query query to look up
   */
  async LookupOrganizationFacilitators(
    cancelToken: CancelTokenSource,
    query?: string
  ): Promise<ListResponse<OrganizationFacilitator>> {
    const path = 'organizations/facilitators/lookup';
    let queryParams: Record<string, any> = {};
    if (query && query.trim().length > 0) {
      const encodedQuery = encodeURIComponent(query);

      queryParams = { search: encodedQuery };
    }
    const data = this.GetAsync<ListResponse<OrganizationFacilitator>>({
      action: path,
      tag: SettingsApiTags.LookupOrganizationFacilitators,
      cancelSource: cancelToken,
      queryParams,
    });
    return data;
  }

  /**
   * get organization group statuses
   * @param query query to look up
   */
  async GetOrganizationGroupStatuses(
    organizationId: string
  ): Promise<ListResponse<OrganizationGroupStatus>> {
    const path = `organizations/${organizationId}/group-status`;
    const data = this.GetAsync<ListResponse<OrganizationGroupStatus>>({
      action: path,
      tag: SettingsApiTags.GetOrganizationGroupStatuses,
    });
    return data;
  }

  // eslint-disable-next-line no-unused-vars
  async ResendInvite(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const encodedEmail = encodeURIComponent(email);
    const path = `user/resend-invite/${encodedEmail}`;
    const response = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SettingsApiTags.ResendInvite,
      cancelSource: cancelToken,
    });
    return response;
  }

  async GetOrganizationInfo(
    orgId: string,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<OrganizationInfo>> {
    const path = `organizations/${orgId}`;
    const response = this.GetAsync<ObjectResponse<OrganizationInfo>>({
      action: path,
      tag: SettingsApiTags.GetOrganizationInfo,
      cancelSource: cancelToken,
    });
    return response;
  }

  async GetOrganizationDefaultCurrencies(
    orgId: string,
    cancelToken: CancelTokenSource,
    query?: string
  ): Promise<ListResponse<Currency>> {
    const search = query && query.trim().length > 0 ? query : '';
    const path = `organizations/${orgId}/currencies`;
    let queryParams: Record<string, any> = {};

    if (query && query.trim().length > 0) {
      queryParams = { search };
    }
    const response = this.GetAsync<ListResponse<Currency>>({
      action: path,
      tag: SettingsApiTags.GetOrganizationDefaultCurrencies,
      cancelSource: cancelToken,
      queryParams,
    });
    return response;
  }

  async GetOrganizationCurrencySettings(
    orgId: string
  ): Promise<ObjectResponse<CurrencySettings>> {
    const path = `organizations/${orgId}/currency-settings`;
    const response = this.GetAsync<ObjectResponse<CurrencySettings>>({
      action: path,
      tag: SettingsApiTags.GetOrganizationDefaultCurrencies,
    });
    return response;
  }

  async SetOrganizationCurrencySettings(
    orgId: string,
    body: CurrencySettingsRequestBody,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `organizations/${orgId}/currency-settings`;
    const response = this.PostAsync<SuccessResponse>({
      action: path,
      body,
      tag: SettingsApiTags.SetOrganizationCurrencySettings,
      cancelSource: cancelToken,
    });
    return response;
  }
}

const SettingsApiInstance = new SettingsApi();

export default SettingsApiInstance;
