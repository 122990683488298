/* eslint-disable */
import Feature from 'api/auth/types/user/Feature';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import StorageKeys from 'constants/StorageKeys';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import LoginResponse from 'api/auth/responses/LoginResponse';
import LocalStorageService from './LocalStorageService';
import RefreshResponse from 'api/auth/responses/RefreshResponse';

class AuthStorageService extends LocalStorageService {
  /**
   * Check if current user is still logged in
   *
   * @returns {boolean} Specifies whether current user's session is valid
   */
  static IsLoggedIn = (): boolean =>
    !!this.GetItem<string>(StorageKeys.TokenKey);

  /**
   * Logs out current user
   */
  static Logout = (): void => {
    this.Clear();
    sendEventGA(CategoryKeysGA.Account, ActionKeysGA.Logout);
    window.location.href = ModulePaths.RootPath;
  };

  /**
   * Writes currently logged-in user's information to local storage
   *
   * @param response Currently logged-in user data
   */
  static OnLogin = (response: LoginResponse): void => {
    this.SetItem<string>(StorageKeys.FirstNameKey, response.firstName);
    this.SetItem<string>(StorageKeys.LastNameKey, response.lastName);
    this.SetItem<string>(
      StorageKeys.OrganizationId,
      response.organizationId
    );
    this.SetItem<string>(StorageKeys.UserNameKey, response.userName);
    this.SetItem<string>(StorageKeys.TokenKey, response.accessToken);
    this.SetItem<string>(StorageKeys.RefreshKey, response.refreshToken);
    this.SetItem<Array<Feature>>(StorageKeys.Features, response.features);
  }

  /**
   * Sets currently logged-in user's tokenKey and refreshKey to local storage
   *
   * @param response Currently logged-in user data
   */
  static OnRefresh = (response: RefreshResponse): void => {
    this.SetItem<string>(StorageKeys.TokenKey, response.accessToken);
    this.SetItem<string>(StorageKeys.RefreshKey, response.refreshToken);
  };
}

export default AuthStorageService;
