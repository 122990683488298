import { useEffect, useRef, useState, FC as ReactFC } from 'react';

import { Formik, FormikHelpers } from 'formik';
import groupBy from 'lodash/groupBy';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Col, Collapse, Fade, Popover, Row } from 'reactstrap';

import ApiError from 'api/common/types/ApiError';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import GrantKeys from 'constants/permissions/GrantKeys';
import { getErrorStatus } from 'helpers/ErrorFormat';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import HttpStatus from 'shared/enums/HttpStatus';
import clearIcon from 'shared/static/img/ic_x.svg';

import CategoryKeysGA from '../../../../../constants/ga/CategoryKeysGA';
import styles from '../permissionsList.module.scss';
import CreatePermissionForm from './create-permission-form/CreatePermissionForm';
import CreatePermissionFormValidation from './CreatePermissionFormValidation';
import CreatePermissionFormValues from './CreatePermissionFormValues';
import PermissionProps from './CreatePermissionItemProps';

const CreatePermissionsListDummyItem: ReactFC<PermissionProps> = (
  props: PermissionProps
) => {
  const {
    defaultClaims,
    dummyItemCollapsed,
    handleDummyItemEdit,
    handleDummyItemCancel,
    organizationId,
    isFirstTime,
    isModalView,
    fetchPermissions,
    removeDummyItem,
    getGettingStartedState,
  } = props;

  const formikRef = useRef<FormikHelpers<CreatePermissionFormValues>>(null);
  const editPermissionRef = useRef<HTMLDivElement>(null);
  const editButtonRef = useRef<HTMLButtonElement>(null);

  const [infoPopoverOpen, setPopoverOpen] = useState(false);
  const [commonError, setCommonError] = useState<null | string>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleInfoHide = (): void => setPopoverOpen(false);

  useEffect(() => {
    setPopoverOpen(true);
    scrollToBottom();

    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, []);

  useEffect(() => {
    if (dummyItemCollapsed && formikRef.current) {
      formikRef.current.resetForm();
      setCommonError(null);
    }
    if (editPermissionRef.current) {
      setPopoverOpen(true);
    }
  }, [dummyItemCollapsed]);

  /**
   * Scroll to the bottom when user added a new permission.
   */
  const scrollToBottom = (): void => {
    if (editPermissionRef !== null) {
      // eslint-disable-next-line no-unused-expressions
      editPermissionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const groupedFeatures = groupBy(defaultClaims, 'groupName');

  /**
   * Handle dummy edit click
   */
  const handleDummyEditClick = (): void => {
    handleDummyItemEdit();
    handleInfoHide();
  };

  /**
   * Handle new permission level edit form submit. called permission
   * level create end point here.
   *
   * @param values form values
   */
  const handleSubmit = async (
    values: CreatePermissionFormValues,
    formikHelpers: FormikHelpers<CreatePermissionFormValues>
  ): Promise<void> => {
    const formattedFeatures = defaultClaims.map((claim) => {
      if (values.features.includes(claim.name)) {
        return {
          name: claim.name,
          enable: true,
        };
      }
      return {
        name: claim.name,
        enable: false,
      };
    });
    const permissionObject = {
      permissions: [
        {
          title: values.title,
          description: values.description,
          features: formattedFeatures,
        },
      ],
    };
    try {
      await SettingsApiInstance.CreatePermissionLevel(
        organizationId,
        permissionObject
      );

      sendEventGA(
        CategoryKeysGA.SettingsPermissions,
        ActionKeysGA.CreatePermission
      );

      if (isFirstTime) {
        getGettingStartedState();
      }
      handleDummyItemCancel();
      fetchPermissions();
      removeDummyItem();
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          setCommonError(intl.get('ERR_PERMISSION_EDIT_FORM_ERRORS'));
        }
      } else {
        setCommonError(intl.get('ERR_PERMISSION_EDIT_FORM_ERRORS'));
      }
      formikHelpers.setStatus(
        getErrorStatus(error, intl.get('OOPS_SOMETHING_WENT_WRONG'))
      );
    }
  };

  /**
   * Render collapsed dummy permission list item with edit button
   */
  const renderDummyPermissionRow = (): JSX.Element => (
    <Col xs="12" className={styles.itemGap}>
      <div className={`${styles.item} ${styles.dummy}`}>
        <Row>
          <Col xs="3" className="d-flex align-items-center">
            <p className="truncate text-16-semibold">
              {intl.get('LBL_SETTINGS_PERMISSION_CUSTOM_PERMISSION_LEVEL')}{' '}
            </p>
            <i className="icon-warning text-orange ml-3 text-20" />
          </Col>
          <Col xs="6" className="d-flex align-items-center">
            <p className="truncate two-lines text-12-medium mb-0">
              {intl.get('LBL_SETTINGS_PERMISSION_YOUR_DESCRIPTION_HERE')}
            </p>
          </Col>
          <Col xs="3" className="d-flex align-items-center justify-content-end">
            <div ref={editPermissionRef}>
              <button
                ref={editButtonRef}
                className="btn btn-secondary btn-sm"
                onClick={handleDummyEditClick}
              >
                <span className="pr-2">{intl.get('BTN_EDIT')}</span>
                <i className={`icon-chevron-down ${styles.reduceIconSize}`} />
              </button>
              <Popover
                isOpen={isFirstTime && infoPopoverOpen}
                placement="bottom"
                hideArrow
                boundariesElement="window"
                container={editPermissionRef}
                modifiers={{
                  preventOverflow: { enabled: false },
                  flip: { behavior: ['bottom'] },
                  hide: { enabled: false },
                }}
                target={editButtonRef}
                delay={{ show: 500, hide: 100 }}
                popperClassName={`edit-helper ${
                  isModalView ? 'modal-view' : ''
                }`}
              >
                <div>
                  <div className="close-container">
                    <button
                      className="insight-close-button"
                      type="button"
                      onClick={handleInfoHide}
                    >
                      <img src={clearIcon} alt="Close" />
                    </button>
                  </div>
                  {intl.get('LBL_SETTINGS_PERMISSION_EDIT_PERMISSION_TOOLTIP')}
                </div>
              </Popover>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );

  const initialValues: CreatePermissionFormValues = {
    title: '',
    description: '',
    features: [
      GrantKeys.DataViewAssignedCountries,
      GrantKeys.DataViewAssignedGroups,
      GrantKeys.DataViewAssignedProjects,
    ],
  };

  return (
    <>
      {!expanded && dummyItemCollapsed && renderDummyPermissionRow()}
      <Collapse
        tag="div"
        isOpen={!dummyItemCollapsed}
        className={`w-100 ${styles.collapseGap}`}
        timeout={300}
        onEntering={(): void => setExpanded(true)}
        onEntered={(): void => setExpanded(false)}
        onExiting={(): void => setExpanded(true)}
        onExited={(): void => setExpanded(false)}
      >
        <Fade timeout={320} in={!dummyItemCollapsed}>
          <Col xs="12">
            <Formik
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={CreatePermissionFormValidation.GetValidationSchema()}
              onSubmit={handleSubmit}
            >
              {({ values, isSubmitting, errors, resetForm }): JSX.Element => (
                <CreatePermissionForm
                  isSubmitting={isSubmitting}
                  values={values}
                  errors={errors}
                  commonError={commonError}
                  onError={setCommonError}
                  groupedFeatures={groupedFeatures}
                  onDummyItemCancel={handleDummyItemCancel}
                  resetForm={resetForm}
                />
              )}
            </Formik>
          </Col>
        </Fade>
      </Collapse>
    </>
  );
};

export default CreatePermissionsListDummyItem;
