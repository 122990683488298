/* eslint-disable */
import StorageKeys from 'constants/StorageKeys';
import { Currency } from 'context/AppProviderState';
import LocalStorageService from './LocalStorageService';

class CurrencyStorageService extends LocalStorageService {
  /**
   * Sets the currency that the currently logged-in user has toggled
   *
   * @param currency Currency object
   */
  static SetSelectedCurrency = (currency: Currency): void => {
    this.SetItem<Currency>(StorageKeys.SelectedCurrency, currency);
  };

  /**
   * Fetches the currency that the currently logged-in user has toggled
   */
  static GetSelectedCurrency = (): Currency =>
    this.GetItem<Currency>(StorageKeys.SelectedCurrency);

  /**
   * Sets the default currency
   *
   * @param currency Currency object
   */
  static SetDefaultCurrency = (currency: Currency): void => {
    this.SetItem<Currency>(StorageKeys.DefaultCurrency, currency);
  };

  /**
   * Fetches the default currency
   */
  static GetDefaultCurrency = (): Currency =>
    this.GetItem<Currency>(StorageKeys.DefaultCurrency);
}

export default CurrencyStorageService;
