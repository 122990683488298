/* eslint-disable */
import UserPermissionsResponse from 'api/auth/responses/UserPermissionsResponse';
import StorageKeys from 'constants/StorageKeys';
import LocalStorageService from './LocalStorageService';

class PermissionStorageService extends LocalStorageService {
  /**
   * Gets currently logged-in user's permissions from local storage
   *
   * @returns {UserPermissionsResponse} Permissions object
   */
  static GetUserPermissions = (): UserPermissionsResponse =>
    this.GetItem<UserPermissionsResponse>(StorageKeys.Permissions);
}

export default PermissionStorageService;
