/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState, FC as ReactFC } from 'react';

import { isEmpty, isEqual, omit } from 'lodash';
import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';

import AuthApiInstance from 'api/auth/AuthApi';
import UserPermissionsResponse from 'api/auth/responses/UserPermissionsResponse';
import ValidatedCurrency from 'api/auth/types/currency/ValidatedCurrency';
import ApiError from 'api/common/types/ApiError';
import ProjectsApiInstance from 'api/projects/ProjectsApi';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ErrorCodes from 'constants/ErrorCodes';
import RegExpressions from 'constants/RegExpressions';
import StorageKeys from 'constants/StorageKeys';
import AppContext from 'context/AppContext';
import { getFirstError, getLocalizedErrorString } from 'helpers/ErrorFormat';
import {
  defaultGlobalFilters,
  getGlobalFilters,
  getGlobalFiltersUrl,
} from 'helpers/GlobalFilterUtils';
import history from 'router-history';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import CurrencyStorageService from 'services/storage-services/CurrencyStorageService';
import FeatureStorageService from 'services/storage-services/FeatureStorageService';
import PermissionStorageService from 'services/storage-services/PermissionStorageService';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import EventKey from 'shared/enums/EventKey';
import ExchangeRate from 'shared/enums/ExchangeRate';
import HttpStatus from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';
import { CallbackAsyncArg, NoParamFuncAsync } from 'shared/types/functionTypes';

import {
  Currency,
  DefaultCurrencySettings,
  GettingStartedStateData,
  GroupStatusData,
  PermissionData,
  ProjectListData,
  UserInfoData,
} from './AppProviderState';

const GenericCurrencyValue: Currency = {
  name: '',
  currencyCode: '',
  symbol: '',
  exchangeRateMode: ExchangeRate.Current,
  status: Status.Loading,
};

const GenericCurrencySettings: DefaultCurrencySettings = {
  currentDefaultCurrency: 'USD',
  currentExchangeRate: ExchangeRate.Current,
  settingsStatus: Status.Loading,
};

const AppProvider: ReactFC = (props) => {
  const { children } = props;

  const location = useLocation();

  /* Persist previous path */
  const [route, setRoute] = useState({
    current: location.pathname,
    previous: location.pathname,
  });

  useEffect(() => {
    setRoute((prevRoute) => ({
      current: location.pathname,
      previous: prevRoute.current,
    }));
  }, [location]);

  let excelReportDownloadCallback: NoParamFuncAsync | null;
  let dashboardSetupCallback: NoParamFuncAsync | null;
  let groupDetailsSetupCallback: CallbackAsyncArg<boolean> | null;

  // prettier-ignore
  const [dashboardSetupInProgress, setDashboardSetupInProgress] = useState<boolean>(false);
  // prettier-ignore
  const [groupDetailsSetupInProgress, setGroupDetailsSetupInProgress] = useState<boolean>(false);
  // prettier-ignore
  const [globalFilters, setGlobalFilters] = useState<GlobalFilters>(defaultGlobalFilters);
  // prettier-ignore
  const [permissionsData, setPermissionsData] = useState<PermissionData>({ status: Status.Loading, silent:false, claims: [], permissionLevel: null });
  // prettier-ignore
  const [gettingStartedStates, setGettingStartedStates] = useState<GettingStartedStateData>({ status: Status.Loading, silent:false, data: null, });
  // prettier-ignore
  const [userInfoData, setUserInfoData] = useState<UserInfoData>({ status: Status.Loading, data: null, features: null });
  // prettier-ignore
  const [fetchedCurrency, setFetchedCurrency] = useState<Currency>(GenericCurrencyValue);
  // prettier-ignore
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>(GenericCurrencyValue);
  // prettier-ignore
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(GenericCurrencyValue);

  const [currencySettings, setCurrencySettings] =
    useState<DefaultCurrencySettings>(GenericCurrencySettings);

  /** Used to hide left nav tooltip while getting started modal is open */
  const [guideOpen, setGuideOpen] = useState<boolean>(false);

  /** Used to show resent invite success */
  // prettier-ignore
  const [resendInviteStatus, setResendInviteStatus] = useState<Status>(Status.Idle);

  /** Used to communicate submit status of settings invite users */
  // prettier-ignore
  const [inviteUsersSubmitting, setInviteUsersSubmitting] = useState<boolean>(false);

  /** Used to store project list for global filter */
  // prettier-ignore
  const [projectListData, setProjectListData] = useState<ProjectListData>({ status:Status.Idle, data:{}, filtered:{} });

  /** Used to store group statuses for global filter */
  // prettier-ignore
  const [groupStatusData, setGroupStatusData] = useState<GroupStatusData>({ status: Status.Idle, data: {}, error: '' });

  /** Message to be displayed on toast for caught errors */
  // prettier-ignore
  const [errorToastText, setErrorToastText] = useState<string | undefined>(undefined);

  /** Header to be displayed on toast for caught errors */
  // prettier-ignore
  const [errorToastHeader, setErrorToastHeader] = useState<string>(intl.get('ERR_TOAST_SOMETHING_WEN_WRONG'));

  /** Message to be displayed on success toast */
  // prettier-ignore
  const [successToastText, setSuccessToastText] = useState<string | undefined>(undefined);

  /** Header to be displayed on success toast */
  // prettier-ignore
  const [successToastHeader, setSuccessToastHeader] = useState<string>(intl.get('LBL_TOAST_ITS_DONE'));

  useEffect(() => {
    EventBus.getInstance().register(EventKey.RequestForbidden, async () => {
      await getPermissions(true);
      setErrorToastText(intl.get('ERR_NO_PERMISSION_MESSAGE'));
    });
    EventBus.getInstance().register(
      EventKey.HandleCustomError,
      (eventArgs: CustomErrorArgs) => {
        const { error, genericErrorString, genericCallback, customCallback } =
          eventArgs;
        const errorCode = getFirstError(error);
        if (errorCode === ErrorCodes.Generic) {
          setErrorToastText(intl.get(genericErrorString));
          genericCallback();
        } else {
          setErrorToastText(intl.get(getLocalizedErrorString(errorCode)));
          customCallback(errorCode);
        }
      }
    );
  });

  /**
   * Hides the error toast
   */
  const hideErrorToast = (): void => {
    setErrorToastText(undefined);
    setErrorToastHeader(intl.get('ERR_TOAST_SOMETHING_WEN_WRONG'));
  };

  /**
   * Hides the success toast
   */
  const hideSuccessToast = (): void => {
    setSuccessToastText(undefined);
    setSuccessToastHeader(intl.get('LBL_TOAST_ITS_DONE'));
  };

  /**
   * Validate currency and set the global filters to context state
   *
   * @param gf Updated global filters
   * @param groupNumber Group Number assigned to a single group
   */
  const setGlobalFiltersWithValidations = (
    gf: GlobalFilters,
    groupNumber?: string
  ): void => {
    if (AuthStorageService.IsLoggedIn()) {
      validateCurrencyToggle(gf, groupNumber).then(() => {
        setGlobalFilters(gf);
      });
    } else {
      setGlobalFilters(gf);
    }
  };

  /**
   * Fetch project list for project global filter to refresh project list
   *
   * @param text Search string
   */
  const getProjectList = async (text?: string): Promise<void> => {
    const globalFiltersSearch = getGlobalFilters(location.search);
    setProjectListData((state) => ({ ...state, status: Status.Loading }));
    try {
      const projects = await ProjectsApiInstance.GetAllProjects(text);
      const projectsNormalized = projects.items.reduce(
        (accumulator, current) => ({
          ...accumulator,
          [current.code]: current,
        }),
        {}
      );

      /* BEGIN - Validate invalid/deleted project (global filter) in search query */
      // Only validate if search term is not present
      if (!text) {
        const projectsList = projects.items.map((item) => item.code);
        const searchProjectsList = globalFiltersSearch.projects;
        const filteredProjects = searchProjectsList.filter((item) =>
          projectsList.includes(item)
        );
        /* If selected projects exist in global filters AND 
          there is a mismatch between all possible projects 
          and any selected projects in global filters */
        const projectsInvalid =
          !isEmpty(searchProjectsList) &&
          !isEqual(filteredProjects, searchProjectsList);
        if (projectsInvalid) {
          /* Display error toast */
          setErrorToastText(
            intl.get('ERR_TOAST_GLOBAL_FILTERS_PROJECTS_MISMATCH')
          );
          const newGlobalFilters = {
            ...globalFilters,
            projects: [],
          };
          validateCurrencyToggle(newGlobalFilters).then(() => {
            /* Redirect app to valid global filter state */
            history.push(getGlobalFiltersUrl(newGlobalFilters, location));
          });
        }
      }
      /* END - Validate invalid/deleted project (global filter) in search query */

      if (text && text.trim().length > 0) {
        setProjectListData((prev) => ({
          status: Status.Success,
          filtered: projectsNormalized,
          data: prev.data,
        }));
      } else {
        setProjectListData({
          status: Status.Success,
          data: projectsNormalized,
          filtered: projectsNormalized,
        });
      }
    } catch (error) {
      setProjectListData({ status: Status.Error, data: {}, filtered: {} });
    }
  };

  /**
   * Fetch group statuses for group status global filter to refresh status list
   */
  const getGroupStatuses = async (): Promise<void> => {
    setGroupStatusData((state) => ({ ...state, status: Status.Loading }));
    let orgId = AuthStorageService.GetItem<string>(StorageKeys.OrganizationId);
    try {
      if (!orgId) {
        const { organizationId } = await AuthApiInstance.GetUserInfo();
        orgId = organizationId;
        AuthStorageService.SetItem<string>(StorageKeys.OrganizationId, orgId);
      }
      const response = await SettingsApiInstance.GetOrganizationGroupStatuses(
        orgId
      );

      const groupStatusNormalized = response.items.reduce(
        (accumulator, item) => ({ ...accumulator, [item.status]: item }),
        {}
      );

      setGroupStatusData({
        data: groupStatusNormalized,
        status: Status.Success,
        error: '',
      });
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = intl.get('ERR_NO_PERMISSION_MESSAGE');
        }
      }
      setGroupStatusData({ status: Status.Error, data: {}, error: errorText });
    }
  };

  /** Functions for dashboard setup */
  const setDashboardSetupCallback = (c: NoParamFuncAsync): void => {
    dashboardSetupCallback = c;
  };

  const startDashboardSetup = (): void => setDashboardSetupInProgress(true);

  const endDashboardSetup = async (): Promise<void> => {
    if (dashboardSetupCallback) {
      await dashboardSetupCallback();
    }
    setDashboardSetupInProgress(false);
  };

  /** Functions for group details setup */
  const setGroupDetailsSetupCallback = (c: CallbackAsyncArg<boolean>): void => {
    groupDetailsSetupCallback = c;
  };

  const startGroupDetailsSetup = (): void =>
    setGroupDetailsSetupInProgress(true);

  const endGroupDetailsSetup = async (cancel = false): Promise<void> => {
    if (groupDetailsSetupCallback) {
      await groupDetailsSetupCallback(cancel);
    }
    setGroupDetailsSetupInProgress(false);
  };

  /** Functions for excel download */
  const setExcelReportDownloadCallback = (callback: NoParamFuncAsync): void => {
    excelReportDownloadCallback = callback;
  };

  const startExcelReportDownload = (): void => {
    if (excelReportDownloadCallback) {
      excelReportDownloadCallback();
    }
  };

  /**
   * Logs out user
   */
  const logoutUser = async (): Promise<void> => {
    try {
      await AuthApiInstance.LogoutAsync();
    } catch (error) {
      // console.log(error);
    } finally {
      AuthStorageService.Logout();
    }
  };

  /**
   * Fetch user permissions and adds them to local-storage
   *
   * @param silent Whether loading permissions should keep app from loading; check PublicRoutes.tsx
   */
  const getPermissions = async (silent?: boolean): Promise<void> => {
    setPermissionsData((prevState) => ({
      ...prevState,
      silent: !!silent,
      status: Status.Loading,
    }));
    try {
      const permissionsResponse = await AuthApiInstance.GetUserPermissions();
      try {
        PermissionStorageService.SetItem<UserPermissionsResponse>(
          StorageKeys.Permissions,
          permissionsResponse
        );
      } catch (error) {
        //   console.log('error:', error);
      } finally {
        setPermissionsData((prevState) => ({
          ...prevState,
          silent: false,
          status: Status.Success,
          ...permissionsResponse,
        }));
      }
    } catch (error) {
      logoutUser();
    }
  };

  /**
   * Fetch getting started states
   *
   * @param silent Whether setting getting started states should keep app from loading; check PublicRoutes.tsx
   */
  const getGettingStartedState = async (silent = false): Promise<void> => {
    setGettingStartedStates((prevState) => ({
      ...prevState,
      silent,
      status: Status.Loading,
    }));
    try {
      const gettingStartedStateResponse =
        await AuthApiInstance.GetGettingStartedState();
      setGettingStartedStates((prevState) => ({
        ...prevState,
        status: Status.Success,
        silent: false,
        data: gettingStartedStateResponse,
      }));
    } catch (error) {
      setGettingStartedStates((prevState) => ({
        ...prevState,
        status: Status.Error,
        silent: false,
        data: null,
      }));
    }
  };

  /**
   * Fetch logged-in user's info
   */
  const getUserInfo = async (): Promise<void> => {
    try {
      setUserInfoData((state) => ({ ...state, status: Status.Loading }));
      setDefaultCurrency((state) => ({ ...state, status: Status.Loading }));
      const userInfo = await AuthApiInstance.GetUserInfo();
      setUserInfoData({
        status: Status.Success,
        data: omit(userInfo, ['features']),
        features: userInfo.features,
      });
      const { currencySettings: settings } = userInfo;
      const newDefaultCurrency: Currency = {
        name: '',
        currencyCode: settings.defaultCurrency,
        symbol: settings.symbol,
        exchangeRateMode: settings.exchangeRateMode,
        status: Status.Idle,
      };

      CurrencyStorageService.SetSelectedCurrency(newDefaultCurrency);
      CurrencyStorageService.SetDefaultCurrency(newDefaultCurrency);
      setSelectedCurrency(newDefaultCurrency);
      setDefaultCurrency(newDefaultCurrency);
    } catch (error) {
      setUserInfoData({
        data: null,
        features: null,
        status: Status.Error,
      });
    }
  };

  /**
   * Fetch default organization currency setting
   */
  const fetchDefaultCurrencySettings = async (): Promise<void> => {
    const organizationId = AuthStorageService.GetItem<string>(
      StorageKeys.OrganizationId
    );
    setCurrencySettings({
      ...currencySettings,
      settingsStatus: Status.Loading,
    });
    try {
      if (organizationId) {
        const response =
          await SettingsApiInstance.GetOrganizationCurrencySettings(
            organizationId
          );
        if (response.item) {
          const { currencyCode, exchangeRateMode } = response.item;
          setCurrencySettings({
            currentDefaultCurrency: currencyCode,
            currentExchangeRate: exchangeRateMode,
            settingsStatus: Status.Success,
          });
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      setCurrencySettings({
        ...GenericCurrencySettings,
        settingsStatus: Status.Error,
      });
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          setErrorToastText(intl.get('ERR_SETTINGS_CURRENCY_SETTINGS_FAILURE'));
        }
      } else {
        setErrorToastText(intl.get('ERR_SETTINGS_CURRENCY_SETTINGS_FAILURE'));
      }
    }
  };

  /**
   * Requests to be called on user login
   *
   * @param gf passing global filters when there is a redirected URL at the login
   */
  const onLogin = async (gf?: GlobalFilters): Promise<void[]> => {
    /* Get the global filters from the search query 
      on initial load as the globalFilters state in
      the context has not been initialized yet */
    const globalFiltersSearch = isEmpty(gf)
      ? getGlobalFilters(location.search)
      : gf;
    return Promise.all([
      /* Fetch and update permissions */
      getPermissions(),
      /* Fetch and update getting started steps */
      getGettingStartedState(),
      /* Fetch and update user info */
      getUserInfo().then(() => {
        fetchDefaultCurrencySettings();
      }),
      /* Validate and update currency toggle restrictions */
      /* Currency validation already occurs when there is a mismatch 
        between the URL and Context state in terms of global filters 
        (see useUrlGlobalFilters hook) i.e. some global filters are 
        set. In instances like this, the following call for currency 
        validation becomes redundant. We therefore, validate the 
        following call to only fire in instances when global filters 
        are not set. */
      isEqual(getGlobalFilters(location.search), defaultGlobalFilters)
        ? validateCurrencyToggle(globalFiltersSearch!) // nosonar
        : Promise.resolve(),
    ]);
  };

  /**
   * Currency validation to switch between local and default currency
   *
   * @param filters Global filters
   * @param groupNumber Group number of the navigated groups details page
   */
  const validateCurrencyToggle = async (
    filters: GlobalFilters,
    groupNumber?: string,
    exitingGroupDetails = false
  ): Promise<void> => {
    const isGroupDetails = RegExpressions.GroupDetailsPath.test(
      location.pathname
    );
    /* If the group details page is browser-refreshed, the initial 
    validation (without the group number) should not be fired */
    if (!(isGroupDetails && !groupNumber) || exitingGroupDetails) {
      try {
        setFetchedCurrency({ ...fetchedCurrency, status: Status.Loading });
        const { fromDate, toDate, groupStatuses, ...rest } = filters;
        const response = await AuthApiInstance.ValidateCurrency({
          groupStatus: groupStatuses,
          groupNumber: groupNumber ?? '',
          ...rest,
        });
        if (response.item) {
          setFetchedCurrency({
            status: Status.Success,
            ...response.item,
          });
          handleSetNewLocalCurrencyOnValidate(response.item);
          /* Handle automatically toggling to default currency if the 
          fetched currency is the same as the default currency */
          handleAutoToggleToDefaultCurrency(response.item.currencyCode);
        } else {
          throw new Error();
        }
      } catch (error) {
        setErrorToastText(intl.get('ERR_CURRENCY_VALIDATE_FAILURE'));
        setFetchedCurrency({
          ...defaultCurrency,
          status: Status.Error,
        });
      }
    } else {
      Promise.resolve();
    }
  };

  /**
   * Toggles the currency between default and local
   */
  const toggleCurrency = (): void => {
    if (fetchedCurrency.currencyCode === defaultCurrency.currencyCode) {
      setErrorToastText(intl.get('ERR_CURRENCY_LOCAL_CURRENCY_DISABLED_HINT'));
    } else if (selectedCurrency.currencyCode === defaultCurrency.currencyCode) {
      CurrencyStorageService.SetSelectedCurrency(fetchedCurrency);
      setSelectedCurrency(fetchedCurrency);
    } else {
      CurrencyStorageService.SetSelectedCurrency(defaultCurrency);
      setSelectedCurrency(defaultCurrency);
    }
  };

  /**
   * When local toggle is enabled when validate is fired and a new
   * local currency is fetched, persist the toggle state and update
   * the selected currency
   *
   * @param fetched Fetched currency
   * */
  const handleSetNewLocalCurrencyOnValidate = (
    fetched: ValidatedCurrency
  ): void => {
    /* We use persisted data for selected and default currencies as the selected
     * and default context states may not be updated at that point in time since
     * setState is an asynchronous function */
    const selectedCurrencyPersisted = CurrencyStorageService.GetItem<Currency>(
      StorageKeys.SelectedCurrency
    );
    const defaultCurrencyPersisted = CurrencyStorageService.GetItem<Currency>(
      StorageKeys.DefaultCurrency
    );
    if (
      fetched.currencyCode !== defaultCurrencyPersisted.currencyCode &&
      fetched.currencyCode !== selectedCurrencyPersisted.currencyCode &&
      selectedCurrencyPersisted.currencyCode !==
        defaultCurrencyPersisted.currencyCode
    ) {
      const newSelectedCurrency: Currency = {
        ...fetched,
        status: Status.Success,
      };
      setSelectedCurrency(newSelectedCurrency);
      CurrencyStorageService.SetSelectedCurrency(newSelectedCurrency);
    }
  };

  /**
   * In an event where the local currency is selected and the validation
   * fetches the default currency (viewing data for groups from multiple
   * countries), the default currency has to be toggled on automatically.
   *
   * @param fetchedCurrencyCode Fetched currency code after validation
   */
  const handleAutoToggleToDefaultCurrency = (
    fetchedCurrencyCode: string
  ): void => {
    const selectedCurrencyPersisted = CurrencyStorageService.GetItem<Currency>(
      StorageKeys.SelectedCurrency
    );
    if (
      selectedCurrencyPersisted.currencyCode !== fetchedCurrencyCode &&
      fetchedCurrencyCode === defaultCurrency.currencyCode
    ) {
      CurrencyStorageService.SetSelectedCurrency(defaultCurrency);
      setSelectedCurrency(defaultCurrency);
    }
  };

  useEffect(() => {
    try {
      /* Reset toasts */
      setErrorToastText(undefined);
      setErrorToastHeader(intl.get('ERR_TOAST_SOMETHING_WEN_WRONG'));
      setSuccessToastText(undefined);
      setSuccessToastHeader(intl.get('LBL_TOAST_ITS_DONE'));

      const userPermissions = PermissionStorageService.GetUserPermissions();
      const userFeatures = FeatureStorageService.GetUserFeatures();
      if (userPermissions.claims) {
        setPermissionsData((prevState) => ({
          ...prevState,
          status: Status.Success,
          ...userPermissions,
        }));
      }
      if (userFeatures) {
        setUserInfoData((prevState) => ({
          ...prevState,
          status: Status.Success,
          features: userFeatures,
        }));
      }
    } catch (error) {
      //   console.log('error:', error);
    }

    if (AuthStorageService.IsLoggedIn()) {
      onLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Validate currency when navigating away from the group details page
   */
  useEffect(() => {
    const isCurrentGroupDetails = RegExpressions.GroupDetailsPath.test(
      location.pathname
    );
    const isPrevGroupDetails = RegExpressions.GroupDetailsPath.test(
      route.previous
    );
    if (!isCurrentGroupDetails && isPrevGroupDetails) {
      validateCurrencyToggle(globalFilters, undefined, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return (
    <AppContext.Provider
      value={{
        dashboardSetupInProgress,
        groupDetailsSetupInProgress,
        globalFilters,
        permissionsData,
        gettingStartedStates,
        guideOpen,
        resendInviteStatus,
        inviteUsersSubmitting,
        projectListData,
        groupStatusData,
        userInfoData,
        fetchedCurrency,
        defaultCurrency,
        selectedCurrency,
        currencySettings,
        setCurrencySettings,
        errorToastText,
        errorToastHeader,
        successToastText,
        successToastHeader,
        setSuccessToastText,
        setSuccessToastHeader,
        setErrorToastText,
        setErrorToastHeader,
        hideSuccessToast,
        hideErrorToast,
        getUserInfo,
        getGroupStatuses,
        getProjectList,
        setInviteUsersSubmitting,
        setResendInviteStatus,
        setGuideOpen,
        getGettingStartedState,
        getPermissions,
        startDashboardSetup,
        startGroupDetailsSetup,
        endDashboardSetup,
        endGroupDetailsSetup,
        setGlobalFilters,
        setGlobalFiltersWithValidations,
        setDashboardSetupCallback,
        setGroupDetailsSetupCallback,
        setExcelReportDownloadCallback,
        startExcelReportDownload,
        onLogin,
        validateCurrencyToggle,
        toggleCurrency,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
